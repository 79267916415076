import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShareDataService } from 'src/app/core/services/share-data.service';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {

  @Input()
  elements: string[] = [];
  @Input()
  selectedElement: string;
  @Input()
  typeIsCluster: boolean;
  @Output()
  childEvent = new EventEmitter();

  favoriteCluster: string;

  constructor(private sharedData: ShareDataService) {}

  ngOnInit() {
    this.favoriteCluster = localStorage.getItem('favorite-cluster');
    if (this.typeIsCluster) {
      if (this.sharedData.getCluster()) {
        this.selectedElement = this.sharedData.getCluster();
      } else if (this.favoriteCluster) {
          this.selectedElement = this.favoriteCluster;
        } else {
          this.sharedData.setCluster(this.selectedElement);
        }
    } else {
      const selectedTeam = this.sharedData.getTeam();
      if (selectedTeam) {
        this.selectedElement = selectedTeam;
      }
    }
    // We wait for elements list to be populated
    if (!this.selectedElement) {
      setTimeout(() => {
        this.selectedElement = this.elements[0];
        this.refresh();
      }, 300);
    }
  }

  refresh() {
    if (this.typeIsCluster) {
      this.sharedData.setCluster(this.selectedElement);
    }
    this.childEvent.emit(this.selectedElement);
  }

  toggleFavorite(favoriteCluster: string) {
    if (this.favoriteCluster === favoriteCluster) {
      this.favoriteCluster = '';
    } else {
      this.favoriteCluster = favoriteCluster;
    }
    localStorage.setItem('favorite-cluster', this.favoriteCluster);
  }
}
