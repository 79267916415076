<div class="d-flex justify-content-between" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="d-flex flex-column">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="actions d-flex flex-column justify-content-center align-items-stretch">
      <button *ngIf="options.closeButton"
        data-toggle="tooltip"
        [title]=" 'NOTIFICATION.CLOSE' | translate "
        (click)="remove()"
        type="button" aria-label="Close" class="toast-close-button" >
        <span aria-hidden="true">×</span>
      </button>
      <div class="mt-auto" *ngIf="options.payload?.linkInfo?.link">
        <a
          [routerLink]="[options.payload.linkInfo?.link]"
          [title]="( 'NOTIFICATION.GOTO' | translate ) + ' ' + ((options.payload.linkInfo?.name)? options.payload.linkInfo?.name : '...')"
          data-toggle="tooltip"
          >
          <i class="fas fa-ellipsis-h see-more-button"></i>
        </a>
      </div>
  </div>
</div>

<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
