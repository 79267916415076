<div class="col-3">
  <mat-form-field appearance="outline">
    <mat-label>{{'SELECT.CLUSTER' | translate}}</mat-label>
    <mat-select [(value)]="selectedElement"
                placeholder="{{ elements.length > 0 ? ('SELECT.ELEMENT' | translate) : ('SELECT.DATA' | translate) }}"
                (selectionChange)="refresh()">
      <mat-select-trigger>
        <span [ngStyle]="{'color': elements.length > 0 ? 'blue' : null}">{{selectedElement}}</span>
      </mat-select-trigger>
      <mat-option *ngFor="let element of elements" value="{{ element }}">
        <mat-icon
          *ngIf="typeIsCluster && favoriteCluster !== element && (element=='ALL' || element=='all')"></mat-icon>
        <mat-icon *ngIf="typeIsCluster && favoriteCluster !== element  && element !== 'ALL' && element !== 'all'"
                  (click)="toggleFavorite(element)">star_border
        </mat-icon>
        <mat-icon *ngIf="typeIsCluster && favoriteCluster === element" (click)="toggleFavorite(element)">star
        </mat-icon>
        <span>{{ element }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
