import { Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { DashNotificationService } from 'src/app/modules/notification/notification.service';
import { ShareDataService } from 'src/app/core/services/share-data.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Resource } from 'src/app/modules/navigation/models/resource';
import { ResourceAlert } from 'src/app/modules/navigation/models/resourceAlert';
import { CommonBaseComponent } from '../common-base/common-base.component';
import { SearchComponent } from '../search/search.component';


@Component({
  selector: 'app-alert-message-base',
  template: `<p>alert-message-base template!</p>`,
  styleUrls: ['./alert-message-base.component.css']
})
export class AlertMessageBaseComponent extends CommonBaseComponent {

  isClosedMessageCluster = false;
  messageCluster = null;
  clusterLoading = true;
  inlinePositionIndex = 0;
  optionsToast: GlobalConfig;
  alertsBaseErrorCount = 0;
  alertsErrorCountAp = 0;
  alertsWarningCount = 0;
  title: string;
  content: string;
  public dataSource = new MatTableDataSource<ResourceAlert>();
  public dataSourceApp = new MatTableDataSource<ResourceAlert>();
  alerts: ResourceAlert[] = [];
  alertsApp: ResourceAlert[] = [];
  resources: Resource[] = [];
  resourcesApp: Resource[] = [];
  loading = true;
  loadingChangeCluster = false;
  @ViewChild('search') search: SearchComponent;
  @Output()
  loadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(ToastContainerDirective) inlineContainers: QueryList<ToastContainerDirective>;
  selectedClusters: Array<string>;

  constructor(
    private toastr: ToastrService,
    protected alertsService: AlertsService,
    protected dashNotifService: DashNotificationService,
    protected translate: TranslateService,
    protected sharedData: ShareDataService,
    protected utilsService: UtilsService
  ) {
    super();
    this.optionsToast = this.toastr.toastrConfig;
  }

  showToast(errorNB: number) {

    this.title = this.translate.instant('ALERT.TITLE');
    this.content = this.translate.instant(
      ( errorNB && errorNB > 1 ) ?
      'ALERT.ERROR.PLURAL':
      'ALERT.ERROR.SINGULAR',
      {'number': errorNB }
    );
    this.dashNotifService.showWarning(this.content, this.title, { link: '/alerts', name: 'Alerts' });
  }

  loadAlertData(clusters) {
    if (localStorage.getItem('alerts')) {
    JSON.parse(localStorage.getItem('alerts')).forEach(
      al => {
        if (al.labels.severity === 'critical') {
          this.alertsBaseErrorCount = this.alertsBaseErrorCount + 1;
        }
      });
    }
    localStorage.setItem('alertsErrorCount', JSON.stringify(this.alertsBaseErrorCount));
    this.resources = [];
    this.resourcesApp = [];
    this.alerts = [];
    this.alertsApp = [];
    this.dataSource.data = [];
    this.dataSourceApp.data = [];
    this.alertsService
      .getAllAlert(false, clusters)
      .subscribe(
        (data)  => this.succesGetAllAlert(data),
        (error) => this.errorGetAllAlert(error)
      )
      .add(() => this.finallyGetAllAlert());
    this.alertsService
      .getAllAlertCloudApp(false, clusters)
      .subscribe(
        (data)  => this.succesGetAllAppAlert(data),
        (error) => this.errorGetAllAlert(error)
      )
      .add(() => this.finallyGetAllAlert());

  }
  succesGetAllAlert(data) {
    Object.keys(data).forEach(key => {
      const type = key.split(': ')[0];
      const name = key.split(': ').length === 1 ? key : key.split(': ')[1];
      const expList = [];
      for (const _alert of data[key]) {
        expList.push(false);
      }
      const res: Resource = new Resource(type, name, data[key], expList);
      res.init_attrs();
      this.resources.push(res);
    });

    this.resources.forEach(res => {
      res.alerts.forEach(alert => {
        const res1: ResourceAlert = new ResourceAlert(res.type, res.name, alert.annotations, alert.labels, alert.endsAt, alert.startsAt, alert.status, res.alerts,
          res.hasCritical, res.clusterName, res.expended);
        this.alerts.push(res1);
      });
    });
    this.dataSource.data = this.alerts;
    this.sortByDueDate();
  }
  setMessage(): void {
    this.clusterLoading = false;
    this.messageCluster = 'Erreur lors du chargement de la liste des clusters';
  }
  errorGetAllAlert(error) {
    console.log(error);
  }

  public sortByDueDate(): void {
    this.alerts.sort((a, b) => {
      return (a.labels.severity < b.labels.severity ? -1 : (a.labels.severity > b.labels.severity ? 1 : new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime()));
    });
    this.alertsApp.sort((a, b) => {
      return (a.labels.severity < b.labels.severity ? -1 : (a.labels.severity > b.labels.severity ? 1 : new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime()));
    });
  }
  succesGetAllAppAlert(data) {
    Object.keys(data).forEach(key => {
      const type = key.split(': ')[0];
      const name = key.split(': ').length === 1 ? key : key.split(': ')[1];
      const expList = [];
      for (const _alert of data[key]) {
        expList.push(false);
      }
      const res: Resource = new Resource(type, name, data[key], expList);
      res.init_attrs();
      this.resourcesApp.push(res);
    });
    this.resourcesApp.forEach(res => {
      res.alerts.forEach(alert => {
        const res1: ResourceAlert = new ResourceAlert(res.type, res.name, alert.annotations, alert.labels, alert.endsAt, alert.startsAt, alert.status, res.alerts,
          res.hasCritical, res.clusterName, res.expended);
        this.alertsApp.push(res1);
      });
    });
    this.dataSourceApp.data = this.alertsApp;
    this.sortByDueDate();
    this.alertsErrorCountAp = 0;
    this.dataSourceApp.data.forEach(
      al => {
        if (al.labels.severity === 'critical') {
          al.status.state ==='active' ? this.alertsErrorCountAp = this.alertsErrorCountAp + 1 : this.alertsErrorCountAp = this.alertsErrorCountAp;
        }
      });
    if (this.alertsErrorCountAp > 0) {
      this.showToast(this.alertsErrorCountAp);
    }
    localStorage.setItem('alertsErrorCount', JSON.stringify(this.alertsErrorCountAp));
    localStorage.setItem('alerts', JSON.stringify(this.dataSourceApp.data));
  }

  finallyGetAllAlert() {
    this.loadingEvent.emit(false);
    this.loadingChangeCluster = false;
    this.loading = false;
    this.search?.updateFilters();
  }

  succesGetClusterList(items) {
    if (items.length > 0) {
      this.clusterList = items;
      if (this.utilsService.hasAtLeastOneElement(this.sharedData.getClusters())) {
        this.selectedClusters = this.sharedData.getClusters();
      } else if (this.atLeastOneFavoriteCluster()) {
        this.selectedClusters = _.cloneDeep(this.favoritesClusters);
      } else {
        this.selectedClusters = this.clusterList;
      }
    }
    this.loadAlertData(this.selectedClusters);
  }
}
