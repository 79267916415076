<form>
  <div class="row pt-4">

    <div class="col-1">
      <label class="col-form-label" for="jobName">{{'SEARCH.JOB-NAME' | translate}}</label>
    </div>

    <div class="col-5">
      <input class="form-control"type="text" id="jobName" name="jobName" placeholder="{{'CI.JOB-PLACEHOLDER' | translate}}" [(ngModel)]="fields.jobName" (ngModelChange)="updateFilters()">
    </div>

    <div class="col-1">
      <label for="startsAtFrom">{{'SEARCH.STARTDATE' | translate}}</label>
    </div>

    <div class="col-2">
      <div class="input-group" [formGroup]="startsAtFromGrp">
        <input class="form-control" placeholder="dd-mm-yyyy hh:mm:ss" name="startsAtFrom" (ngModelChange)="updateDateFilter($event, 'startsAtFrom')"
        formControlName="startsAtFromCtrl">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" #startsAtFromPopoverNavigation="ngbPopover" [autoClose]="'outside'"
          [ngbPopover]="startsAtFromCalendarContent" placement="bottom-right" type="button" (click)="toggle(startsAtFromPopoverNavigation, 'startsAtFrom')">
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="col-1 text-center">/</div>

    <div class="col-2">
      <div class="input-group" [formGroup]="startsAtToGrp">
        <input class="form-control" placeholder="dd-mm-yyyy hh:mm:ss" name="startsAtTo" (ngModelChange)="updateDateFilter($event, 'startsAtTo')"
        formControlName="startsAtToCtrl">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" #startsAtToPopoverNavigation="ngbPopover" [autoClose]="'outside'"
          [ngbPopover]="startsAtToCalendarContent" placement="bottom-right" type="button" (click)="toggle(startsAtToPopoverNavigation, 'startsAtTo')">
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row pt-4">

    <div class="col-6">

      <mat-slide-toggle
        labelPosition="before"
        [(ngModel)]="fields.checkbox.jobSucceed"
        id="jobSucceed"
        value="jobsSucceeded"
        name="jobSucceed"
        [checked]="fields.checkbox.jobSucceed"
        (change)="updateFilters()">
        {{'SEARCH.JOBS-SUCCESS' | translate}}
      </mat-slide-toggle>
    </div>

    <div class="col-1">
      <label for="endAtFromGrp">{{'SEARCH.ENDDATE' | translate}}</label>
    </div>

    <div class="col-2">
      <div class="input-group" [formGroup]="endAtFromGrp">
        <input class="form-control" placeholder="dd-mm-yyyy hh:mm:ss" name="endAtFrom" (ngModelChange)="updateDateFilter($event, 'endAtFrom')"
        formControlName="endAtFromCtrl">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" #endAtFromPopoverNavigation="ngbPopover" [autoClose]="'outside'"
          [ngbPopover]="endAtFromCalendarContent" placement="bottom-right" type="button" (click)="toggle(endAtFromPopoverNavigation, 'endAtFrom')">
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="col-1 text-center">/</div>

    <div class="col-2">
      <div class="input-group" [formGroup]="endAtToGrp">
        <input class="form-control" placeholder="dd-mm-yyyy hh:mm:ss" name="endAtTo" (ngModelChange)="updateDateFilter($event, 'endAtTo')"
        formControlName="endAtToCtrl">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" #endAtToPopoverNavigation="ngbPopover" [autoClose]="'outside'"
          [ngbPopover]="endAtToCalendarContent" placement="bottom-right" type="button" (click)="toggle(endAtToPopoverNavigation, 'endAtTo')">
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>

  </div>
</form>

<ng-template #startsAtFromCalendarContent>
  <div [formGroup]="startsAtFromPickerGrp" style="overflow: visible;">
    <ngb-datepicker #startsAtFromdp="ngbDatepicker" (ngModelChange)="updatePicker('startsAtFrom')" name="startsAtFromdb" formControlName="startsAtFromCtrldp">
      <div *ngIf="startsAtFromPopoverNavigation.isOpen() && startsAtFromCtrl.status === 'VALID' ? navigation(startsAtFromdp, 'startsAtFrom'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('startsAtFrom')" name="startsAtFromtp" formControlName="startsAtFromCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>

<ng-template #startsAtToCalendarContent>
  <div [formGroup]="startsAtToPickerGrp">
    <ngb-datepicker #startsAtTodp="ngbDatepicker" (ngModelChange)="updatePicker('startsAtTo')" name="startsAtTodp" formControlName="startsAtToCtrldp">
      <div *ngIf="startsAtToPopoverNavigation.isOpen() && startsAtToCtrl.status === 'VALID' ? navigation(startsAtTodp, 'startsAtTo'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('startsAtTo')" name="startsAtTotp" formControlName="startsAtToCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>

<ng-template #endAtFromCalendarContent>
  <div [formGroup]="endAtFromPickerGrp">
    <ngb-datepicker #endAtFromdp="ngbDatepicker" (ngModelChange)="updatePicker('endAtFrom')" name="endAtFromdb" formControlName="endAtFromCtrldp">
      <div *ngIf="endAtFromPopoverNavigation.isOpen() && endAtFromCtrl.status === 'VALID' ? navigation(endAtFromdp, 'endAtFrom'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('endAtFrom')" name="endAtFromtp" formControlName="endAtFromCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>

<ng-template #endAtToCalendarContent>
  <div [formGroup]="endAtToPickerGrp">
    <ngb-datepicker #endAtTodp="ngbDatepicker" (ngModelChange)="updatePicker('endAtTo')" name="endAtTodp" formControlName="endAtToCtrldp">
      <div *ngIf="endAtToPopoverNavigation.isOpen() && endAtToCtrl.status === 'VALID' ? navigation(endAtTodp, 'endAtTo'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('endAtTo')" name="endAtTotp" formControlName="endAtToCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>
