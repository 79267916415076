import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Team } from 'src/app/modules/navigation/models/team';
import { ShareCiDataService } from 'src/app/core/services/share-ci-data.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { MultipleSelectionSelectComponent } from '../multiple-selection-select/multiple-selection-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multiple-selection-select-all',
  templateUrl: '../multiple-selection-select/multiple-selection-select.component.html',
  styleUrls: ['../multiple-selection-select/multiple-selection-select.component.css']
})
export class MultipleSelectionSelectAllComponent extends MultipleSelectionSelectComponent implements OnInit, OnDestroy, AfterViewInit {

  /** list of teams */
  private _teams: Map<string, Team>;

  @Input()
  set teams(teams: Array<Team>) {
    this._teams = new Map();
    for (const team of teams) {
      this._teams.set(team.name, team);
    }
    this.selectValues = Array.from(this._teams.keys());
  }

  get teams() {
    return Array.from(this._teams.values());
  }

  constructor(private sharedData: ShareCiDataService, utilsService: UtilsService, private translate: TranslateService) {
    super(utilsService);
    this.selectKey = 'teams';
    this.placeholder = 'Team(s)';
    this.useFavorites = true;
    this.allowClearFavorites = false;
    this.selectValuesAttribute = 'name';  // get 'name' attribute on Team objects
    this.attrInsteadOfSelectValues = 'teams';  // get this.teams instead of this.selectValues
    this.changeOnInit = false;
  }

  ngOnInit() {
    this.label = this.translate.instant('SELECT.TEAM');
    this.searchString = this.translate.instant('SELECT.FIND_TEAM');
    this.notFoundString = this.translate.instant('SELECT.NO_TEAMS');
    let selectedTeam;
    if (this.sharedData.getTeam() != null) {
      this.teams.filter(team => {
        if (team.name.toLowerCase().indexOf(this.sharedData.getTeam()) > -1) {
          selectedTeam = team;
        }
      });
    }
    this.initialize(selectedTeam);
  }

  get localStorageKey(): string {
    return this.sharedData.teamsStorageKey;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    this.setInitialValue();
    this.sharedData.setTeam(null);
  }

  protected setInitialValue() {
    this.filteredSelectMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: Team, b: Team) => a && b && a.id === b.id;
      });
    this.changeMultipleSelect.emit(this.selectMultiCtrl);
  }

  get favoriteTeams() {
    return this.getFavoriteObjects();
  }

  hasFavorites(): boolean {
    if (super.hasFavorites()) {
      if (this.favoriteTeams.length > 0) {
        return true;
      } else {
        // Some favorite teams were set, but are not sent by the server anymore
        this.emptyFavorites();
      }
    }
    return false;
  }
}
