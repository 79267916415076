<mat-card class="mb-3 p-0">
  <div *ngIf="!resource.hasCritical">
    <div class="card-header bg-warning wrapping-none">
      <h5 class="row pr-1">
        <span class="col-11 text-container font-weight-bold" style="font-size:1.2rem" ngbTooltip="{{resource.name}}">{{ resource.name }}</span>
        <span class="col-1 badge badge-pill badge-dark float-right text-warning">{{ resource.alertSize }}</span>
      </h5>
    </div>
  </div>
  <div *ngIf="resource.hasCritical">
    <div class="card-header bg-danger wrapping-none">
      <h5 class="row pr-1">
        <span class="col-11 text-container font-weight-bold" style="font-size:1.2rem" ngbTooltip="{{resource.name}}">{{ resource.name }}</span>
        <span class="col-1 badge badge-pill badge-dark float-right text-danger">{{ resource.alertSize }}</span>
      </h5>
    </div>
  </div>
  <div *ngFor="let alert of resource.alerts | alertFilterChampsClass2:filters; let i = index" class="card border-0 p-0">
    <app-alert-element [alert]="alert"></app-alert-element>
  </div>
</mat-card>
