import { Component, OnInit, Input } from '@angular/core';
import { MetricValues } from 'src/app/modules/navigation/models/metric-values';

@Component({
  selector: 'app-node-card',
  templateUrl: './node-card.component.html',
  styleUrls: ['./node-card.component.css']
})
export class NodeCardComponent implements OnInit {
  @Input ()
  nodes: MetricValues[];
  @Input ()
  title;
  @Input ()
  type;
  constructor() { }

  ngOnInit() {
  }



}
