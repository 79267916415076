<div class="card mb-3">

  <div *ngIf="pipeline.status === 'failed' || pipeline.status === 'errored'">
    <div class="pl-2 card-header border-danger bg-danger wrapping-none font-weight-bold text-container">
      <div class="car-title text-dark">
        <span class="pr-2 ">{{pipeline.name}} ({{pipeline.id}})</span>
        <a href="{{ pipeline.url }}" target="blank" class="text-dark" target="_blank">
          <i class="fa fa-link" aria-hidden="true"></i>
        </a>
        <i *ngIf="!pipeline.expended" class="float-right fas fa-plus-circle" style="cursor:pointer"
           (click)="expandRow(pipeline)"></i>
        <i *ngIf="pipeline.expended" class="float-right fas fa-minus-circle" style="cursor:pointer"
           (click)="expandRow(pipeline)"></i>
      </div>
    </div>
  </div>
  <div *ngIf="pipeline.status === 'aborted'">
    <div class="card-header bg-warning wrapping-none font-weight-bold text-container">
      <div class="car-title text-dark text-">
        <span>{{pipeline.name}} ({{pipeline.id}})
          <a href="{{ pipeline.url }}" target="blank" class="text-dark" target="_blank">
          <i class="fa fa-link" aria-hidden="true"></i></a>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="pipeline.status === 'succeeded'">
    <div class="card-header border-success bg-success wrapping-none font-weight-bold text-container">
      <div class="car-title text-dark">
        <span>{{pipeline.name}} ({{pipeline.id}})
          <a href="{{ pipeline.url }}" target="blank" class="text-dark" target="_blank">
            <i class="fa fa-link" aria-hidden="true"></i>
          </a>
        </span>
        <i *ngIf="!pipeline.expended" class="float-right fas fa-plus-circle" style="cursor:pointer"
           (click)="expandRow(pipeline)"></i>
        <i *ngIf="pipeline.expended" class="float-right fas fa-minus-circle" style="cursor:pointer"
           (click)="expandRow(pipeline)"></i>
      </div>
    </div>
  </div>
  <div *ngIf="pipeline.status === 'started'">
    <div class="card-header bg-warning wrapping-none font-weight-bold text-container">
      <div class="car-title text-dark text-">
        <span>{{pipeline.name}} ({{pipeline.id}})
          <a href="{{ pipeline.url }}" target="blank" class="text-dark" target="_blank">
            <i class="fa fa-link" aria-hidden="true"></i>
          </a>
        </span>
        <i *ngIf="!pipeline.expended" class="float-right fas fa-plus-circle" style="cursor:pointer"
           (click)="expandRow(pipeline)"></i>
        <i *ngIf="pipeline.expended" class="float-right fas fa-minus-circle" style="cursor:pointer"
           (click)="expandRow(pipeline)"></i>
      </div>
    </div>
  </div>
  <div *ngIf="pipeline.status === 'paused'">
    <div class="card-header border-primary bg-primary wrapping-none font-weight-bold text-container">
      <div class="car-title text-dark">
        <span>{{pipeline.name}} ({{pipeline.id}})
          <a href="{{ pipeline.url }}" target="blank" class="text-dark" target="_blank">
            <i class="fa fa-link" aria-hidden="true"></i>
          </a>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="pipeline.status === 'pending'">
    <div class="card-header border-light blue-grey lighten-3 wrapping-none font-weight-bold text-container">
      <div class="car-title text-dark">
        <span>{{pipeline.name}} ({{pipeline.id}})
          <a href="{{ pipeline.url }}" target="blank" class="text-dark" target="_blank">
            <i class="fa fa-link" aria-hidden="true"></i>
          </a>
        </span>
      </div>
    </div>
  </div>

  <div *ngFor="let job of pipeline.jobs">
    <div class="card-body shadow-none p-0 border-bottom" *ngIf="pipeline.expended">
      <table class="table-borderless text-dark w-100">
        <tr [ngClass]="{'table-danger': job?.displayedBuild?.status === 'failed' || job?.displayedBuild?.status === 'errored',
                        'table-warning': job?.displayedBuild?.status === 'aborted',
                        'table-success': job?.displayedBuild?.status === 'succeeded',
                        'table-warning': job?.displayedBuild?.status === 'started'}">
          <ng-template #tipContent>
            <div [innerHtml]="job.displayedBuild.logs_text | safeHtml"></div>
          </ng-template>
          <td class="pl-1">{{job.id}}</td>
          <td>{{job.name}}</td>
          <td class="pointer" (click)="open(job)">
            <span *ngIf="job?.displayedBuild?.status === 'failed' || job?.displayedBuild?.status === 'errored' || job?.displayedBuild?.status === 'succeeded' || job?.displayedBuild?.status === 'started'"
                  class="fa fa-search"></span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
