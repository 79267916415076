export enum StatusField {
  started = 'started',
  failed = 'failed',
  errored = 'errored',
  aborted = 'aborted',
  paused = 'paused',
  succeeded = 'succeeded',
  pending = 'pending'
}

export const StatusFieldErrorValues: Array<string> = [
  StatusField.failed,
  StatusField.errored
];

export const StatusFieldFinishedValues: Array<string> = StatusFieldErrorValues.concat([StatusField.succeeded]);

export enum BuildAttrs {
  next_build = 'next_build',
  finished_build = 'finished_build',
  transition_build = 'transition_build'
}

export const DisplayedBuildAttrs = [BuildAttrs.next_build, BuildAttrs.finished_build];
