import { Component, OnInit, Input, ViewEncapsulation, Inject, LOCALE_ID } from '@angular/core';

import { Pipeline } from 'src/app/modules/navigation/models/pipeline';
import { JobsPipeline } from 'src/app/modules/navigation/models/jobsPipeline';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { CiService } from 'src/app/core/services/ci.service';
import { StatusFieldErrorValues } from 'src/app/shared/enums/pipeline-status-field.enum';


@Component({
  selector: 'app-pipeline-card',
  templateUrl: './pipeline-card.component.html',
  styleUrls: ['./pipeline-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PipelineCardComponent implements OnInit {

  @Input()
  pipeline: Pipeline;
  options: NgbModalOptions;

  constructor(private modalService: NgbModal, @Inject(LOCALE_ID) private locale: string, private ciService: CiService) {
    this.options = {size: 'lg', scrollable: true};
  }

  ngOnInit() {
    if (StatusFieldErrorValues.includes(this.pipeline.status)) {
      this.pipeline.expended = true;
    } else {
      this.pipeline.expended = false;
    }
  }

  expandRow(pipeline: Pipeline): void {
    pipeline.expended = !pipeline.expended;
  }

  open(job: JobsPipeline) {
    this.ciService.openPipelineModal(this.modalService, this.options, this.locale, this.pipeline, job);
  }
}

// @Component({
//   selector: "dialog-overview-example-dialog",
//   templateUrl: "dialog-overview-example-dialog.html"
// })
// export class DialogOverviewExampleDialog {
//   constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>) {}
//
//   onNoClick(): void {
//     this.dialogRef.close();
//   }
// }
