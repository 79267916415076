<div class="p-0 m-0">
  <div [ngClass]="{'silent-alert': alert.status.state === 'suppressed'}" class="card-body" (click)="expandRow()">
    <div>
      <h5 class="badge {{ alert.labels.severity === 'critical' ? 'badge-danger' : 'badge-warning' }}"style="font-size: 0.8rem;">
        {{ alert.labels.alertname }}
        <i *ngIf="alert.status.state === 'suppressed'" class="fas fa-volume-mute fa-lg marginLeft" placement="bottom" ngbTooltip="This alert has been silenced">
        </i>
      </h5>

      <p *ngIf="alert.endsAt != null">{{ alert.startsAt | date:'dd/MM/yyyy HH:mm:ss' }}</p>
      <h5 class="badge badge-light">Cluster: {{ alert.labels.cluster }}</h5>
    </div>
  </div>
  <div class="card-footer" *ngIf="expended">{{ alert.annotations.message }}</div>
</div>
