import {Component, Input, OnInit} from '@angular/core';
import {Alert} from '../../../modules/navigation/models/alert';


@Component({
  selector: 'app-alert-element',
  templateUrl: './alert-element.component.html',
  styleUrls: ['./alert-element.component.css']
})
export class AlertElementComponent implements OnInit {

  @Input()
  alert: Alert;

  expended: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  expandRow(): void {
    this.expended = !this.expended;
  }
}
