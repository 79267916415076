import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifContainerComponent } from './notif-container/notif-container.component';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NotifToastComponent } from './notif-toast/notif-toast.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    NotifContainerComponent,
    NotifToastComponent
  ],
  imports: [
    CommonModule,
    ToastContainerModule,
    ToastrModule.forRoot({
      toastComponent: NotifToastComponent,
      progressBar: true,
      // positionClass: 'inline', // top right (under nav-bar)
      positionClass: 'toast-bottom-right',
      maxOpened: 5,
      closeButton: true,
      timeOut: 10000,
      extendedTimeOut: 5000,
      preventDuplicates: true,

      // uncomment to debug :
      // disableTimeOut: true,
      // tapToDismiss: false,
    }),
    RouterModule,
    TranslateModule
  ],
  exports: [
    NotifContainerComponent
  ],
  entryComponents: [NotifToastComponent],
})
export class NotificationModule { }
