import { Pipe, PipeTransform } from '@angular/core';
import { Alert } from '../modules/navigation/models/alert';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Pipe({
  name: 'alertFilterChampsClass2'
})
export class AlertFilterChampsClass2Pipe implements PipeTransform {

  transform(alertes: Alert[], filters: any): any[] {
    if (!alertes || !filters || 
          (    Object.keys(filters['class2']['text']).length === 0 &&
            Object.keys(filters['class2']['date']).length === 0 &&
            Object.keys(filters['class2']['checkbox']).length === 0 &&
            filters['class2']['typeAlert']['silentAlert'] &&
            filters['class2']['typeAlert']['nonSilentAlert']
          )
      ) {
      return alertes;
    }

    return alertes.filter(alerte => {
      var allConditionRespected = true;

      if(allConditionRespected  && !filters['class2']['typeAlert']['silentAlert'] && !filters['class2']['typeAlert']['nonSilentAlert']){
        allConditionRespected = false;
      }

      if(allConditionRespected && filters['class2']['typeAlert']['silentAlert'] && !filters['class2']['typeAlert']['nonSilentAlert']){
        allConditionRespected = alerte.status.state === 'suppressed';
      }

      if(allConditionRespected && !filters['class2']['typeAlert']['silentAlert'] && filters['class2']['typeAlert']['nonSilentAlert']){
        allConditionRespected = alerte.status.state !== 'suppressed';
      }
      
      if (allConditionRespected && filters['class2']['checkbox']['critical'] && filters['class2']['checkbox']['warning']) {
        allConditionRespected = Object.keys(filters['class2']['checkbox']).includes(alerte.labels.severity);
      }else{
        if(allConditionRespected && filters['class2']['checkbox']['critical']){
          allConditionRespected = alerte.labels.severity === 'critical'
        }
        if(allConditionRespected && filters['class2']['checkbox']['warning']){
          allConditionRespected = alerte.labels.severity === 'warning'
        }
        if (allConditionRespected && !filters['class2']['checkbox']['critical'] && !filters['class2']['checkbox']['warning']){
          allConditionRespected = !Object.keys(filters['class2']['checkbox']).includes(alerte.labels.severity);
        }
      }

      if (allConditionRespected && filters['class2']['text'].hasOwnProperty('alertName')) {
        allConditionRespected = alerte.labels.alertname.toLowerCase().includes(filters['class2']['text']['alertName'].toLowerCase()) ? allConditionRespected : false;
      }
      if (allConditionRespected && filters['class2']['text'].hasOwnProperty('alertMessage')) {
        allConditionRespected = alerte.annotations.message.toLowerCase().includes(filters['class2']['text']['alertMessage'].toLowerCase()) ? allConditionRespected : false;
      }
      if (allConditionRespected && (filters['class2']['date'].hasOwnProperty('startsAtFrom') || filters['class2']['date'].hasOwnProperty('startsAtTo'))) {
        let startsAtFromFilter: number = filters['class2']['date']['startsAtFrom'];
        let startsAtToFilter: number = filters['class2']['date']['startsAtTo'];
        let startsAtValue = this.parse(alerte.startsAt);
        allConditionRespected = (startsAtFromFilter === undefined || startsAtFromFilter === null  || startsAtValue>=startsAtFromFilter) &&
        (startsAtToFilter === undefined || startsAtToFilter === null || startsAtValue<=startsAtToFilter) ? allConditionRespected : false;
      }
      if (allConditionRespected && (filters['class2']['date'].hasOwnProperty('endAtFrom') || filters['class2']['date'].hasOwnProperty('endAtTo'))) {
        let endAtFromFilter: number = filters['class2']['date']['endAtFrom'];
        let endAtToFilter: number = filters['class2']['date']['endAtTo'];
        let endsAtValue = this.parse(alerte.endsAt);
        allConditionRespected = (endAtFromFilter === undefined || endAtFromFilter === null ||  endsAtValue>=endAtFromFilter) &&
        (endAtToFilter === undefined || endAtToFilter === null || endsAtValue <= endAtToFilter) ? allConditionRespected : false;
      }
      return allConditionRespected;
    });
  }

  parse(value: string): number{
    return moment(value, "YYYY-MM-DDThh:mm:ss.SSSZ").toDate().getTime();
 }
}
