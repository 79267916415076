import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { NotificationLink } from './model/notif-link.model';


@Injectable({
providedIn: 'root'
})
export class DashNotificationService {

	NOTIF_DISABLED = "notifDisabled";
	notifDisabled: boolean = undefined;

	constructor(private notifService: ToastrService) {

	}

	enableNotifications() {
		localStorage.setItem(this.NOTIF_DISABLED, 'false');
		this.notifDisabled = false;
	}

	disableNotifications() {
		localStorage.setItem(this.NOTIF_DISABLED, 'true');
		this.notifDisabled = true;
	}

	isNotifDisabled(): boolean {
		if (this.notifDisabled !== undefined) return this.notifDisabled;
		return this.notifDisabled = (localStorage.getItem(this.NOTIF_DISABLED) === 'true');
	}

	showSuccess(message: string, title: string, linkInfo?: NotificationLink, toastrConfig?: Partial<IndividualConfig>) {
		if (this.isNotifDisabled() || !message) return;
		this.notifService.success(message, title, { payload: { linkInfo}, ...toastrConfig});
    }
	
    showError(message: string, title: string, linkInfo?: NotificationLink, toastrConfig?: Partial<IndividualConfig>) {
        if (this.isNotifDisabled() || !message) return;
        this.notifService.error(message, title, { payload: { linkInfo }, ...toastrConfig });
    }

    showInfo(message: string, title: string, linkInfo?: NotificationLink, toastrConfig?: Partial<IndividualConfig>) {
        if (this.isNotifDisabled() || !message) return;
        this.notifService.info(message, title, { payload: { linkInfo }, ...toastrConfig});
    }

    showWarning(message: string, title: string, linkInfo?: NotificationLink, toastrConfig?: Partial<IndividualConfig>) {
        if (this.isNotifDisabled() || !message) return;
        this.notifService.warning(message, title, { payload: { linkInfo }, ...toastrConfig });
    }

}