import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { NodeCardComponent } from './components/node-card/node-card.component';
import { NodeItemComponent } from './components/node-item/node-item.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { CardsModule } from 'angular-bootstrap-md';
import { AlertCardComponent } from './components/alert-card/alert-card.component';
import { AlertElementComponent } from './components/alert-element/alert-element.component';
import { PipelineCardComponent } from './components/pipeline-card/pipeline-card.component';
import { RefreshPageComponent } from './components/refresh-page/refresh-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from './components/search/search.component';
import { AlertFilterChampsClass1Pipe } from '../pipe/alert-filter-champs-class1.pipe';
import { AlertFilterChampsClass2Pipe } from '../pipe/alert-filter-champs-class2.pipe';
import { SelectComponent } from './components/select/select.component';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbdModalContentComponent } from './components/ngbd-modal-content/ngbd-modal-content.component';
import { TranslatePageComponent } from './components/translate/translate.component';
import { MultipleSelectionSelectAllComponent } from './components/multiple-selection-select-all/multiple-selection-select-all.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SearchPipelineJobComponent } from './components/search-pipeline-job/search-pipeline-job.component';
import { MultipleSelectionSelectAllClusterComponent } from './components/multiple-selection-select-all-cluster/multiple-selection-select-all-cluster.component';
import { AlertFilterChampsClass3Pipe } from '../pipe/alert-filter-champs-class3.pipe';
import { MultipleSelectionSelectComponent } from './components/multiple-selection-select/multiple-selection-select.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { AlertMessageBaseComponent } from './components/alert-message-base/alert-message-base.component';
import { CommonBaseComponent } from './components/common-base/common-base.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [NodeCardComponent,
        NodeItemComponent,
        DashboardCardComponent,
        AlertCardComponent,
        AlertElementComponent,
        PipelineCardComponent, RefreshPageComponent, SearchComponent, TranslatePageComponent,
        AlertFilterChampsClass1Pipe,
        AlertFilterChampsClass2Pipe,
        AlertFilterChampsClass3Pipe,
        SelectComponent,
        SafeHtmlPipe,
        NgbdModalContentComponent,
        MultipleSelectionSelectAllComponent,
        SearchPipelineJobComponent,
        MultipleSelectionSelectAllClusterComponent,
        MultipleSelectionSelectComponent,
        AlertMessageBaseComponent,
        CommonBaseComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        MatDatepickerModule,
        MatNativeDateModule,
        CardsModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        TranslateModule.forChild({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [
        NodeCardComponent,
        NodeItemComponent,
        DashboardCardComponent,
        AlertCardComponent,
        PipelineCardComponent,
        RefreshPageComponent,
        SearchComponent,
        AlertFilterChampsClass1Pipe,
        AlertFilterChampsClass2Pipe,
        AlertFilterChampsClass3Pipe,
        SelectComponent,
        SafeHtmlPipe,
        TranslateModule,
        TranslatePageComponent,
        MultipleSelectionSelectAllComponent,
        SearchPipelineJobComponent,
        MultipleSelectionSelectAllClusterComponent,
        MultipleSelectionSelectComponent
    ],
    providers: []
})
export class SharedModule { }
