import { Component, OnInit, Input } from '@angular/core';
import { MetricValues } from 'src/app/modules/navigation/models/metric-values';

@Component({
  selector: 'app-node-item',
  template: `
  <li  class="list-group-item d-flex justify-content-between align-items-center" style="font-size:16px;">
 {{ itemName.split(".")[0] }}
    <span
    [ngClass]="{'badge-success': isInfo, 'badge-warning': isWarning, 'badge-danger': isDanger, 'badge' : true, 'badge-pill' : true}">
    {{ node.value[1] | number : '1.0-0'  }} {{unity}}
    </span>
  </li>
  `
})
export class NodeItemComponent implements OnInit {
  @Input ()
  node: MetricValues;
  unity = '';

  @Input ()
  type;

  itemName: string;

  isInfo: boolean;
  isWarning: boolean;
  isDanger: boolean;
  constructor() { }

  ngOnInit() {
    this.badgeClass();
    this.setItemName();
  }

  badgeClass() {
    if (this.type === 'pod') {
      if (+this.node.value[1] < 25) {
        this.isInfo = true;
      } else if (+this.node.value[1] < 50) {
        this.isWarning = true;
      } else {
        this.isDanger = true;
      }
    } else if (this.type === 'memory') {
      this.unity = '';
      if (+this.node.value[1] < 15) {
        this.isInfo = true;
      } else if (+this.node.value[1] < 25) {
        this.isWarning = true;
      } else {
        this.isDanger = true;
      }
    } else if (this.type === 'CPU') {
      this.unity = '%';
      if (+this.node.value[1] < 25) {
        this.isInfo = true;
      } else if (+this.node.value[1] < 50) {
        this.isWarning = true;
      } else {
        this.isDanger = true;
      }
    } else {
      this.isInfo = true;
    }
  }

  setItemName() {
    if (this.node.metric.fqdn) {
      this.itemName = this.node.metric.fqdn;
    } else {
      this.itemName = this.node.metric.instance;
    }
  }

}
