import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ClusterVersion } from 'src/app/modules/navigation/models/cluster-version';
import { PipelineFilter } from 'src/app/modules/navigation/models/pipeline-filter';
import { StatusFieldErrorValues } from 'src/app/shared/enums/pipeline-status-field.enum';
import { doOnSubscribe } from 'src/app/util/rxjsUtil';
import { CiService } from './ci.service';
import { LanguageService } from './language.service';
import { DashNotificationService } from '../../modules/notification/notification.service';
import { NotificationLink } from 'src/app/modules/notification/model/notif-link.model';
import { ContainersService } from 'src/app/core/services/containers.service';
import { switchMap } from 'rxjs/operators';

interface SubscriptionQueryRefs {
  [index: string]: Subscription;
}

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  public teamName: string;
  public clusterName: string;
  public listClusterVersion: Array<ClusterVersion>;
  private clusters: Array<string>;
  public title: string;
  public showPods: boolean;
  public showService: boolean;
  public showVolume: boolean;
  public showIngress: boolean;
  ciType: string;
  ciErrors: number;
  content: string;
  ciErrorsLoading = true;
  @ViewChildren(ToastContainerDirective) inlineContainers: QueryList<ToastContainerDirective>;
  inlinePositionIndex = 0;
  optionsToast: GlobalConfig;
  subscriptionQueryRefs: SubscriptionQueryRefs = {};
  public podsErrorLoading: boolean;
  public podsErrorCount: number;
  constructor(
    public languageService: LanguageService,
    private ciService: CiService,
    private toastr: ToastrService,
    private dashNotifService: DashNotificationService,
    private translate: TranslateService,
    private containersService: ContainersService
    ) {
      this.optionsToast = this.toastr.toastrConfig;
    }

  getTeam(): string {
    return this.teamName;
  }

  setCiType(ciType: string) {
    this.ciType = ciType;
  }

  setCiErrors(ciErrors: number) {
    this.ciErrors = ciErrors;
  }

  setTeam(teamName: string) {
    this.teamName = teamName;
  }

  getCluster(): string {
    return this.clusterName;
  }

  setCluster(clusterName: string) {
    this.clusterName = clusterName;
  }

  getListClusterVersion(): Array<ClusterVersion> {
    return this.listClusterVersion;
  }

  setListClusterVersion(listClusterVersion: Array<ClusterVersion>) {
    return this.listClusterVersion = listClusterVersion;
  }

  getClusters(): Array<string> {
    return this.clusters;
  }

  setClusters(clusters: Array<string>) {
    return this.clusters = clusters;
  }

  setTitle(title: string) {
    this.title = title;
  }

  getTitle() {
    return this.title;
  }

  setShowPods(showPods: boolean) {
    this.showPods = showPods;
  }

  getShowServices() {
    return this.showService;
  }
  setShowServices(showService: boolean) {
    this.showService = showService;
  }

  getShowPods() {
    return this.showPods;
  }
  getShowIngress() {
    return this.showIngress;
  }
  setShowIngress(showIngress: boolean) {
    this.showIngress = showIngress;
  }
  getShowVolumes() {
    return this.showVolume;
  }
  setShowVolumes(showVolume: boolean) {
    this.showVolume = showVolume;
  }

  countPipelines(pipelineFilter) {
    return this.ciService.countPipelines(pipelineFilter, true).pipe(
      doOnSubscribe(() => {
        this.ciErrorsLoading = true;
        if (!this.ciErrors) {
          this.ciErrors = 0;
        }
      })
    );
  }

  getCiErrors() {
    this.unsubscribeSubscription('countPipelines');
    const pipelineFilter = new PipelineFilter();
    pipelineFilter.job_status_in = StatusFieldErrorValues;
    this.subscriptionQueryRefs.countPipelines = this.countPipelines(pipelineFilter).subscribe(
      numErrors => {
        this.ciType = numErrors > 0 ? 'danger' : 'success';
        this.ciErrorsLoading = false;
        if (numErrors > this.ciErrors) {
          this.showToast(
            numErrors,
            'ALERT.CI.ERROR.SINGULAR',
            'ALERT.CI.ERROR.PLURAL',
            { link: '/ci', name:'Pipelines' }
          );
        }
        this.ciErrors = numErrors;
      },
      err => {
        console.log(err);
        this.unsubscribeSubscription('countPipelines');
      },
      () => {
        this.ciErrorsLoading = false;
        this.unsubscribeSubscription('countPipelines');
      }
    );
  }

  getAllPodsErrorCount(clustersObservable: Observable<string[]>) {
    this.podsErrorLoading = true;
    let podsErrorCount = 0;
    clustersObservable.pipe(
      switchMap(clusters => this.containersService.getAllPodsInError(clusters))
    ).subscribe(
      data => {
        if ([undefined, null].includes(this.podsErrorCount)) {
          this.podsErrorCount = 0;
        }
        podsErrorCount += data.length;
        this.podsErrorLoading = false;
        if (podsErrorCount > this.podsErrorCount) {
          this.showToast(
            podsErrorCount,
            'ALERT.PODS.ERROR.SINGULAR',
            'ALERT.PODS.ERROR.PLURAL',
            {link: '/containers', name: 'Containers'},
            'error'
          );
        }
        this.podsErrorCount = podsErrorCount;
      }
    );
  }

  showToast(numErrors: number, singular: string, plural: string, linkInfo: NotificationLink, level:string='warning') {
    const title = this.translate.instant('ALERT.TITLE');
    const content = this.translate.instant(
      (numErrors && numErrors > 1) ? plural: singular,
      { 'number': numErrors }
      );
    switch(level) {
      case 'warning': {
        this.dashNotifService.showWarning(content, title, linkInfo);
        break;
      }
      case 'error': {
        this.dashNotifService.showError(content, title, linkInfo);
        break;
      }
    }
  }

  unsubscribeSubscription(queryRefName) {
    if (queryRefName in this.subscriptionQueryRefs) {
      const queryRef = this.subscriptionQueryRefs[queryRefName];
      console.log(`Unsubscribing to ${queryRefName}`);
      queryRef.unsubscribe();
      while (!queryRef.closed) {
        // We just wait for the subscription to close
        console.log(`Waiting or ${queryRefName} to close`);
      }
      delete this.subscriptionQueryRefs[queryRefName];
    }
  }

  unsubscribeAllSubscriptions() {
    for (const queryRefName of Object.keys(this.subscriptionQueryRefs)) {
      console.log(`Calling unsubscribe for ${queryRefName}`);
      this.unsubscribeSubscription(queryRefName);
    }
  }
}
