import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor( private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
              this.router.navigate(['/notFound']);
              console.log('error 401');
            }
            if (err.status.toString().startsWith('4')) {
            this.router.navigate(['/unavailable']);
            console.log('error 4XX');
          }
            if (err.status.toString().startsWith('5')) {
              // this.router.navigate(['/serverError']);
              console.log('error 5XX');
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }

}
