import { Alert} from './alert';

export class Resource {
  type: string;
  name: string;
  alerts: Alert[];
  alertSize: number;
  hasCritical: boolean;
  clusterName: string;
  expended: boolean[];

  constructor(type, name, alerts: Alert[], expended) {
    this.type = type;
    this.name = name;
    this.alerts = alerts;
    this.expended = expended;
  }

  init_attrs(alerts?: Alert[]){
    let usedAlerts = this.alerts;
    if (alerts) {
      usedAlerts = alerts;
    }
    this.alertSize = usedAlerts.length;
    usedAlerts.forEach(alert => {
        this.clusterName = alert.labels.cc_prom_source;
        if (!alert.labels.cluster) {
          // alert.labels.cluster is not send often by the backend.
          // alert.labels.cc_prom_source is always there and is the cluster name.
          alert.labels.cluster = alert.labels.cc_prom_source;
        }
        if (alert.labels.severity === 'critical') {
          this.hasCritical = true;
        }
      });
  }
}
