export class Params {
  active: boolean;
  clusterList: Array<string>;

  constructor(active: boolean, clusterList: Array<string>) {
    this.active = active;
    this.clusterList = clusterList;
  }

}
