import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

interface HasNamespace {
  namespace: string;
}

interface NamespaceEvent {
  value: Array<string>;
}

interface Component {
  dataSource: MatTableDataSource<any>;
  havePagination: boolean;
  pageSize: number;
  count: number;
  paginator: MatPaginator;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }

  hasAtLeastOneElement(handled): boolean {
    return handled !== null && handled !== undefined && (handled?.length > 0 || handled?.size > 0);
  }

  hasLocalStorageKey(key): boolean {
    return localStorage.getItem(key) !== null;
  }

  getLocalStorageParsedJsonData(key, _default?) {
    if (this.hasLocalStorageKey(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    return _default;
  }
}
