import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakSecurityService } from '../services/KeycloakService';

@Injectable({
  providedIn: 'root'
})
export class KeycloakHttpInterceptorService implements HttpInterceptor {

  constructor(private kcService: KeycloakSecurityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.kcService.isLogged()) {
      return next.handle(req);
    }

    const request = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.kcService.getToken(),
      }
    });
    return next.handle(request);
  }

}
