import { Component, OnInit, Output, EventEmitter, Inject, LOCALE_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { PipelineFormFilterFields } from '../../../modules/navigation/models/pipeline-filter';

@Component({
  selector: 'app-search-pipeline-job',
  templateUrl: './search-pipeline-job.component.html',
  styleUrls: ['./search-pipeline-job.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchPipelineJobComponent implements OnInit {

  regexDateTime = '(0[1-9]|[1-2][0-9]|3[0-1])[-](0[1-9]|1[0-2])[-][0-9]{4} (00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])$';
  startsAtFromCtrldp: FormControl;
  startsAtToCtrldp: FormControl;
  startsAtFromCtrltp: FormControl;
  startsAtToCtrltp: FormControl;
  startsAtFromCtrl: FormControl;
  startsAtToCtrl: FormControl;
  startsAtFromGrp: FormGroup;
  startsAtToGrp: FormGroup;
  startsAtFromPickerGrp: FormGroup;
  startsAtToPickerGrp: FormGroup;

  endAtFromCtrldp: FormControl;
  endAtToCtrldp: FormControl;
  endAtFromCtrltp: FormControl;
  endAtToCtrltp: FormControl;
  endAtFromCtrl: FormControl;
  endAtToCtrl: FormControl;
  endAtFromGrp: FormGroup;
  endAtToGrp: FormGroup;
  endAtFromPickerGrp: FormGroup;
  endAtToPickerGrp: FormGroup;

  startsAtFromPopoverNavigation = false;
  startsAtToPopoverNavigation = false;
  endAtFromPopoverNavigation = false;
  endAtToPopoverNavigation = false;

  @Output()
  groupFilters: EventEmitter<any> = new EventEmitter<any>();
  filters: any;
  inputDatetimeFormat = 'dd-MM-yyyy HH:mm:ss';
  date: any;
  fields = new PipelineFormFilterFields();

  constructor(private fb: FormBuilder, @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit() {
    this.startsAtFromCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.startsAtFromCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.startsAtFromCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.startsAtFromPickerGrp = this.fb.group({
      startsAtFromCtrldp: this.startsAtFromCtrldp,
      startsAtFromCtrltp: this.startsAtFromCtrltp,
    });
    this.startsAtFromGrp = this.fb.group({
      startsAtFromCtrl: this.startsAtFromCtrl,
      startsAtFromPicker: this.startsAtFromPickerGrp
    });

    this.startsAtToCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.startsAtToCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.startsAtToCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.startsAtToPickerGrp = this.fb.group({
      startsAtToCtrldp: this.startsAtToCtrldp,
      startsAtToCtrltp: this.startsAtToCtrltp,
    });
    this.startsAtToGrp = this.fb.group({
      startsAtToCtrl: this.startsAtToCtrl,
      startsAtToPicker: this.startsAtToPickerGrp
    });

    this.endAtFromCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.endAtFromCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.endAtFromCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.endAtFromPickerGrp = this.fb.group({
      endAtFromCtrldp: this.endAtFromCtrldp,
      endAtFromCtrltp: this.endAtFromCtrltp,
    });
    this.endAtFromGrp = this.fb.group({
      endAtFromCtrl: this.endAtFromCtrl,
      endAtFromPicker: this.endAtFromPickerGrp
    });

    this.endAtToCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.endAtToCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.endAtToCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.endAtToPickerGrp = this.fb.group({
      endAtToCtrldp: this.endAtToCtrldp,
      endAtToCtrltp: this.endAtToCtrltp,
    });
    this.endAtToGrp = this.fb.group({
      endAtToCtrl: this.endAtToCtrl,
      endAtToPicker: this.endAtToPickerGrp
    });
    this.updateFilters();
  }

  createNgbDateStruct(startsAtFromValueMoment: moment.Moment): NgbDateStruct {
    return {
      year: startsAtFromValueMoment.year(), month: startsAtFromValueMoment.month() + 1, day: startsAtFromValueMoment.date()
    };
  }

  createNgbTimeStruct(startsAtFromValueMoment: moment.Moment): NgbTimeStruct {
    return {
      hour: startsAtFromValueMoment.hour(), minute: startsAtFromValueMoment.minute(), second: startsAtFromValueMoment.second()
    };
  }

  initTimePicker(): NgbTimeStruct {
    const dateA = new Date();
    return { hour: dateA.getHours(), minute: dateA.getMinutes(), second: dateA.getSeconds() };
  }

  initDatePicker(): NgbDateStruct {
    const dateA = new Date();
    return { year: dateA.getFullYear(), month: dateA.getMonth() + 1, day: dateA.getDate() };
  }


  compareDate(startsAtFromValueMoment: moment.Moment, filterName: string): boolean {
    return (this[filterName + 'Ctrldp'].value.year === startsAtFromValueMoment.year() &&
    this[filterName + 'Ctrldp'].value.month === (startsAtFromValueMoment.month() + 1) &&
    this[filterName + 'Ctrldp'].value.day === startsAtFromValueMoment.date() &&
    this[filterName + 'Ctrltp'].value.hour === startsAtFromValueMoment.hour() &&
    this[filterName + 'Ctrltp'].value.minute === startsAtFromValueMoment.minute() &&
    this[filterName + 'Ctrltp'].value.second === startsAtFromValueMoment.second());
  }

  toggle(popover, filterName: string) {
    this[filterName + 'PopoverNavigation'] = popover.isOpen() ? false : true;
  }

  navigation(datePickerTemplate: NgbDatepicker, filterName: string) {
    if (this[filterName + 'PopoverNavigation']) {
      datePickerTemplate.navigateTo(this[filterName + 'Ctrldp'].value);
    }
    this[filterName + 'PopoverNavigation'] = false;
  }


  updateDateFilter(inputValue: string, filterName: string) {
    if (this[filterName + 'Ctrl'].status === 'VALID' && this[filterName + 'Ctrl'].value != null
        && this[filterName + 'Ctrl'].value !== undefined) {

      const momentValue = moment(inputValue, 'DD-MM-YYYY hh:mm:ss');
      this.fields.date[filterName] = momentValue.unix();
      if (!this.compareDate(momentValue, filterName)) {
        this[filterName + 'Ctrldp'].setValue(this.createNgbDateStruct(momentValue));
        this[filterName + 'Ctrltp'].setValue(this.createNgbTimeStruct(momentValue));
      }
    }else{
      this.fields.date[filterName] = null;
    }
    this.updateFilters();
  }

  updatePicker(filterName: string): void {
    if (this[filterName + 'Ctrltp'].status !== 'VALID' || this[filterName + 'Ctrltp'].value === null || this[filterName + 'Ctrltp'].value === undefined) {
      this[filterName + 'Ctrltp'].setValue(this.initTimePicker());
    }
    const date = new Date(
      this[filterName + 'Ctrldp'].value.year, this[filterName + 'Ctrldp'].value.month - 1,
      this[filterName + 'Ctrldp'].value.day, this[filterName + 'Ctrltp'].value.hour,
      this[filterName + 'Ctrltp'].value.minute, this[filterName + 'Ctrltp'].value.second
    );
    this[filterName + 'Ctrl'].setErrors(null);
    this[filterName + 'Ctrl'].setValue(new DatePipe(this.locale).transform(date, this.inputDatetimeFormat));
  }

  updateFilters(): void {
    this.filters = this.fields.getFormFilters();
    this.groupFilters.emit(this.filters);
  }
}
