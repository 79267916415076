<div class="row">

  <div class="col-1">
    <label for="alertMessage">{{'SEARCH.MESSAGE' | translate}}</label>
  </div>

  <div class="col-5">
    <input type="text" class="form-control" id="alertMessage" name="alertMessage" placeholder="{{'SEARCH.PLACEHOLDER.MESSAGE' | translate}}" [(ngModel)]="fields.class2.text.alertMessage" (ngModelChange)="updateFilters()">
  </div>

  <div class="col-1">
    <label for="alertName">{{'SEARCH.NAME' | translate}}</label>
  </div>

  <div class="col-5">
    <input type="text" class="form-control" id="alertName" name="alertName" placeholder="{{'SEARCH.PLACEHOLDER.NAME' | translate}}" [(ngModel)]="fields.class2.text.alertName" (ngModelChange)="updateFilters()">
  </div>
</div>

<div class="row pt-4">

  <div class="col-1">
    <label for="startsAtFromGrp">{{'SEARCH.STARTDATE' | translate}}</label>
  </div>

  <div class="col-2">
    <div class="input-group" [formGroup]="startsAtFromGrp">
      <input class="form-control" placeholder="{{'SEARCH.PLACEHOLDER.DATE' | translate}}" name="startsAtFrom" (ngModelChange)="updateDateFilter($event, 'startsAtFrom')" formControlName="startsAtFromCtrl">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" #startsAtFromPopoverNavigation="ngbPopover" [autoClose]="'outside'" [ngbPopover]="startsAtFromCalendarContent" placement="bottom-right" type="button" (click)="toggle(startsAtFromPopoverNavigation, 'startsAtFrom')">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-1 text-center">/</div>

  <div class="col-2">
    <div class="input-group" [formGroup]="startsAtToGrp">
      <input class="form-control" placeholder="{{'SEARCH.PLACEHOLDER.DATE' | translate}}" name="startsAtTo" (ngModelChange)="updateDateFilter($event, 'startsAtTo')" formControlName="startsAtToCtrl">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" #startsAtToPopoverNavigation="ngbPopover" [autoClose]="'outside'" [ngbPopover]="startsAtToCalendarContent" placement="bottom-right" type="button" (click)="toggle(startsAtToPopoverNavigation, 'startsAtTo')">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>


  <div class="col-1">
    <label for="alertOrigin">{{'SEARCH.ORIGIN' | translate}}</label>
  </div>

  <div class="col-5">
    <input type="text" class="form-control" id="alertOrigin" name="alertOrigin" placeholder="{{'SEARCH.PLACEHOLDER.ORIGIN' | translate}}" [(ngModel)]="fields.class1.text.alertOrigin" (ngModelChange)="updateFilters()">
  </div>
</div>

<div class="row pt-4">

  <div class="col-1">
    <label for="endAtFrom">{{'SEARCH.ENDDATE' | translate}}</label>
  </div>

  <div class="col-2">
    <div class="input-group" [formGroup]="endAtFromGrp">
      <input class="form-control" placeholder="{{'SEARCH.PLACEHOLDER.DATE' | translate}}" name="endAtFrom" (ngModelChange)="updateDateFilter($event, 'endAtFrom')" formControlName="endAtFromCtrl">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" #endAtFromPopoverNavigation="ngbPopover" [autoClose]="'outside'" [ngbPopover]="endAtFromCalendarContent" placement="bottom-right" type="button" (click)="toggle(endAtFromPopoverNavigation, 'endAtFrom')">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-1 text-center">/</div>

  <div class="col-2">
    <div class="input-group" [formGroup]="endAtToGrp">
      <input class="form-control" placeholder="{{'SEARCH.PLACEHOLDER.DATE' | translate}}" name="endAtTo" (ngModelChange)="updateDateFilter($event, 'endAtTo')" formControlName="endAtToCtrl">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" #endAtToPopoverNavigation="ngbPopover" [autoClose]="'outside'" [ngbPopover]="endAtToCalendarContent" placement="bottom-right" type="button" (click)="toggle(endAtToPopoverNavigation, 'endAtTo')">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-1">
  </div>

  <div class="form-check col-2">
    <mat-slide-toggle [checked]="fields.class2.checkbox.warning" (change)="warningAlert()">
      {{'SEARCH.WARNINGS' | translate}}
    </mat-slide-toggle>
  </div>

  <div class="col-1">
  </div>

  <div class="form-check col-2">
    <mat-slide-toggle [checked]="fields.class2.typeAlert.silentAlert" (change)="silentAlert()">
      {{'SEARCH.SILENCES' | translate}}
    </mat-slide-toggle>
  </div>

</div>

<div class="row">
  <div class="col-12 pt-4 pb-4">
  </div>
</div>

<div class="row">
  <div class="col-3">
    <a class="btn btn-block {{favoriteFilterSaved && !filterModified? 'btn-light' : 'btn-warning'}} text-dark" role="button" (click)="saveFavoriteSearch()">
      <i class="fa fa-star mr-1" aria-hidden="true"></i>
      <span *ngIf="favoriteFilterSaved && !filterModified">{{'SEARCH.FILTER.FAVORITE.SAVED' | translate}}</span>
      <span *ngIf="!favoriteFilterSaved || filterModified">{{'SEARCH.FILTER.FAVORITE.SAVE' | translate}}</span>
    </a>
  </div>
  <div class="col-2">
    <a class="btn btn-block {{!filterBlank ? 'btn-light text-dark' : 'btn-secondary'}}" role="button" (click)="clearFilter()">
      <i class="fa fa-trash mr-1" aria-hidden="true"></i>
      <span class="textMargin">{{'SEARCH.FILTER.CLEAR' | translate}}</span>
    </a>
  </div>
  <div class="col-3">
    <a class="btn btn-block {{favoriteFilterSaved ? 'btn-danger' : 'btn-secondary'}}" role="button" (click)="clearFavoriteSearch()">
      <i class="fa fa-trash mr-1" aria-hidden="true"></i>
      <span class="textMargin">{{'SEARCH.FILTER.FAVORITE.CLEAR' | translate}}</span>
    </a>
  </div>
  <div class="col-4"></div>
</div>

<ng-template #startsAtFromCalendarContent>
  <div [formGroup]="startsAtFromPickerGrp">
    <ngb-datepicker #startsAtFromdp="ngbDatepicker" (ngModelChange)="updatePicker('startsAtFrom')" name="startsAtFromdb" formControlName="startsAtFromCtrldp">
      <div *ngIf="startsAtFromPopoverNavigation.isOpen() && startsAtFromCtrl.status === 'VALID' ? navigation(startsAtFromdp, 'startsAtFrom'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('startsAtFrom')" name="startsAtFromtp" formControlName="startsAtFromCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>

<ng-template #startsAtToCalendarContent>
  <div [formGroup]="startsAtToPickerGrp">
    <ngb-datepicker #startsAtTodp="ngbDatepicker" (ngModelChange)="updatePicker('startsAtTo')" name="startsAtTodp" formControlName="startsAtToCtrldp">
      <div *ngIf="startsAtToPopoverNavigation.isOpen() && startsAtToCtrl.status === 'VALID' ? navigation(startsAtTodp, 'startsAtTo'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('startsAtTo')" name="startsAtTotp" formControlName="startsAtToCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>

<ng-template #endAtFromCalendarContent>
  <div [formGroup]="endAtFromPickerGrp">
    <ngb-datepicker #endAtFromdp="ngbDatepicker" (ngModelChange)="updatePicker('endAtFrom')" name="endAtFromdb" formControlName="endAtFromCtrldp">
      <div *ngIf="endAtFromPopoverNavigation.isOpen() && endAtFromCtrl.status === 'VALID' ? navigation(endAtFromdp, 'endAtFrom'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('endAtFrom')" name="endAtFromtp" formControlName="endAtFromCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>

<ng-template #endAtToCalendarContent>
  <div [formGroup]="endAtToPickerGrp">
    <ngb-datepicker #endAtTodp="ngbDatepicker" (ngModelChange)="updatePicker('endAtTo')" name="endAtTodp" formControlName="endAtToCtrldp">
      <div *ngIf="endAtToPopoverNavigation.isOpen() && endAtToCtrl.status === 'VALID' ? navigation(endAtTodp, 'endAtTo'):false"></div>
    </ngb-datepicker>
    <ngb-timepicker [seconds]="true" (ngModelChange)="updatePicker('endAtTo')" name="endAtTotp" formControlName="endAtToCtrltp">
    </ngb-timepicker>
  </div>
</ng-template>
