import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'app-multiple-selection-select',
  templateUrl: './multiple-selection-select.component.html',
  styleUrls: ['./multiple-selection-select.component.css']
})
export class MultipleSelectionSelectComponent implements OnInit {

  /** This key is used to store favorites in local storage and to set <p id="select-component-{{selectKey}}"> in the dom. */
  @Input()
  selectKey: string;

  /** This is the list of values to select */
  @Input()
  selectValues: Array<string>;

  @Input()
  selectedList: Array<string>;

  @Input()
  label: string | null;

  @Input()
  placeholder: string | null;

  @Input()
  searchString: string;

  @Input()
  notFoundString: string;

  @Input()
  useFavorites = false;

  @Input()
  allowClearFavorites = false;

  /** call change method on init */
  @Input()
  changeOnInit: boolean;

  @Output()
  changeMultipleSelect: EventEmitter<FormControl> = new EventEmitter<FormControl>();

  /** control for the selected name for multi-selection */
  public selectMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public selectMultiFilterCtrl: FormControl = new FormControl();

  /** list of names filtered by search keyword */
  public filteredSelectMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  /** Attribute to fetch on this instance instead of this.selectValues */
  protected attrInsteadOfSelectValues: string;

  /** Attribute of this[attrInsteadOfSelectValues] or this.selectValues objects to display if they are not strings */
  protected selectValuesAttribute: string;

  constructor(protected utilsService: UtilsService) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize(initialValue?): void {
    let selectValues : Array<string>;
    if (this.attrInsteadOfSelectValues) {
      selectValues = this[this.attrInsteadOfSelectValues];
    } else {
      selectValues = this.selectValues;
    }

    if (initialValue) {
      this.selectMultiCtrl.setValue([initialValue]);
    } else {
      const allFavorites = this.getFavoriteObjects();
      if (this.hasFavorites() && allFavorites.length > 0) {
        this.selectMultiCtrl.setValue(allFavorites);
      } else {
        this.selectMultiCtrl.setValue(selectValues);
      }
    }
    // load the initial name list
    this.filteredSelectMulti.next(selectValues.slice());
    // listen for search field value changes
    this.selectMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSelectMulti();
      });
    if (this.changeOnInit) {
      this.change();
    }
  }

  getSelectValueStr(value) {
    if (typeof value === 'string' || this.selectValuesAttribute === undefined) {
      return value;
    }
    return value[this.selectValuesAttribute];
  }

  get placeholderOrLabel(): string | null {
    return this.placeholder ? this.placeholder : this.label;
  }

  get labelOrPlaceholder(): string | null {
    return this.label ? this.label : this.placeholder;
  }

  setList(list: Array<string>) {
    return this.selectedList = list;
  }

  get localStorageKey(): string {
    return `favorite_${this.selectKey}`;
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredSelectMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
          if (selectAllValue) {
            this.selectMultiCtrl.patchValue(val);
          } else {
            this.selectMultiCtrl.patchValue([]);
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.change();
        }
      );
  }

  toggleAllCheckboxChecked() {
    return this.utilsService.hasAtLeastOneElement(this.selectMultiCtrl.value) && this.selectMultiCtrl.value?.length === this.selectValues.length;
  }

  private favoritesInLocalStorage() {
    return localStorage.getItem(this.localStorageKey) !== null;
  }

  getFavorites(): Array<string> {
    if (this.favoritesInLocalStorage()) {
      return JSON.parse(localStorage.getItem(this.localStorageKey));
    }
    return [];
  }

  getFavoriteObjects() {
    const allFavorites = this.getFavorites();
    const objects = [];
    if (!this.attrInsteadOfSelectValues) {
      for (const value of this.selectValues) {
        if (allFavorites.includes(value)) {
          objects.push(value);
        }
      }
    } else {
      for (const object of this[this.attrInsteadOfSelectValues]) {
        if (allFavorites.includes(this.getSelectValueStr(object))) {
          objects.push(object);
        }
      }
    }
    return objects;
  }

  isFavorite(object: any) {
    const name = this.getSelectValueStr(object);
    return this.getFavorites().includes(name);
  }

  setFavorite(object: any) {
    const name = this.getSelectValueStr(object);
    const favorites = this.getFavorites();
    if (!favorites.includes(name)) {
      favorites.push(name);
      localStorage.setItem(this.localStorageKey, JSON.stringify(favorites));
    }
  }

  unsetFavorite(object: any) {
    const name = this.getSelectValueStr(object);
    const favorites = this.getFavorites();
    const favoriteIndex = favorites.indexOf(name);
    if (favoriteIndex >= 0) {
      favorites.splice(favoriteIndex, 1);
      localStorage.setItem(this.localStorageKey, JSON.stringify(favorites));
    }
  }

  toggleFavorite(event, object: any) {
    if (this.isFavorite(object)) {
      this.unsetFavorite(object);
    } else {
      this.setFavorite(object);
    }
    event.stopPropagation();
  }

  emptyFavorites() {
    localStorage.setItem(this.localStorageKey, JSON.stringify([]));
  }

  hasFavorites() {
    return this.getFavorites().length > 0;
  }

  protected filterSelectMulti() {
    let selectValues : Array<string>;
    if (this.attrInsteadOfSelectValues === undefined) {
      selectValues = this.selectValues;
    } else {
      selectValues = this[this.attrInsteadOfSelectValues];
    }
    if (!selectValues) {
      return;
    }
    // get the search keyword
    let search = this.selectMultiFilterCtrl.value;
    if (!search) {
      this.filteredSelectMulti.next(selectValues.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the names
    this.filteredSelectMulti.next(
      selectValues.filter(name => this.getSelectValueStr(name).toLowerCase().indexOf(search) > -1)
    );
  }

  change(_event?) {
    this.setList(this.selectMultiCtrl.value);
    this.changeMultipleSelect.emit(this.selectMultiCtrl);
  }
}
