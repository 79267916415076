import { Pipe, PipeTransform } from '@angular/core';
import { Alert } from '../modules/navigation/models/alert';
import { Resource } from '../modules/navigation/models/resource';
import * as moment from 'moment';

@Pipe({
  name: 'alertFilterChampsClass1'
})
export class AlertFilterChampsClass1Pipe implements PipeTransform {

  transform(resources: Resource[], filters: any): any[] {
    if (!resources || !filters ||
      (Object.keys(filters.class1.text).length === 0 &&
        Object.keys(filters.class2.text).length === 0 &&
        Object.keys(filters.class2.checkbox).length === 0 &&
        Object.keys(filters.class2.date).length === 0 &&
        filters.class2.typeAlert.silentAlert &&
        filters.class2.typeAlert.nonSilentAlert
      )
    ) {
      return resources.map(res => {
        res.init_attrs();
        return res;
      });
    }

    return resources.filter(
      res => {
        const filteredAlert = this.filtrerChampsClass2(res.alerts, filters);
        const hasFiltrerChampsClass2 = filteredAlert.length > 0;
        res.init_attrs(filteredAlert);

        if (filters.class1.text.hasOwnProperty('alertOrigin')) {
          return hasFiltrerChampsClass2 && res.name.toLowerCase().includes(filters.class1.text.alertOrigin.toLowerCase());
        } else {
          return hasFiltrerChampsClass2;
        }

      });


  }

  filtrerChampsClass2(alertes: Alert[], filters: any): Alert[] {
    return alertes.filter(alerte => {
      let allConditionRespected = true;

      if (allConditionRespected && !filters.class2.typeAlert.silentAlert && !filters.class2.typeAlert.nonSilentAlert) {
        allConditionRespected = false;
      }

      if (allConditionRespected && filters.class2.typeAlert.silentAlert && !filters.class2.typeAlert.nonSilentAlert) {
        allConditionRespected = alerte.status.state === 'suppressed';
      }

      if (allConditionRespected && !filters.class2.typeAlert.silentAlert && filters.class2.typeAlert.nonSilentAlert) {
        allConditionRespected = alerte.status.state !== 'suppressed';
      }

      if (allConditionRespected && filters.class2.checkbox.critical && filters.class2.checkbox.warning) {
        allConditionRespected = Object.keys(filters.class2.checkbox).includes(alerte.labels.severity);
      } else {
        if (allConditionRespected && filters.class2.checkbox.critical) {
          allConditionRespected = alerte.labels.severity === 'critical';
        }
        if (allConditionRespected && filters.class2.checkbox.warning) {
          allConditionRespected = alerte.labels.severity === 'warning';
        }
        if (allConditionRespected && !filters.class2.checkbox.critical && !filters.class2.checkbox.warning) {
          allConditionRespected = !Object.keys(filters.class2.checkbox).includes(alerte.labels.severity);
        }
      }

      if (allConditionRespected && filters.class2.text.hasOwnProperty('alertName')) {
        allConditionRespected = alerte.labels.alertname.toLowerCase().includes(filters.class2.text.alertName.toLowerCase()) ? allConditionRespected : false;
      }
      if (allConditionRespected && filters.class2.text.hasOwnProperty('alertMessage')) {
        allConditionRespected = alerte.annotations.message.toLowerCase().includes(filters.class2.text.alertMessage.toLowerCase()) ? allConditionRespected : false;
      }
      if (allConditionRespected && (filters.class2.date.hasOwnProperty('startsAtFrom') || filters.class2.date.hasOwnProperty('startsAtTo'))) {
        const startsAtFromFilter: number = filters.class2.date.startsAtFrom;
        const startsAtToFilter: number = filters.class2.date.startsAtTo;
        const startsAtValue = this.parse(alerte.startsAt);
        allConditionRespected = (startsAtFromFilter === undefined || startsAtFromFilter === null || startsAtValue >= startsAtFromFilter) &&
        (startsAtToFilter === undefined || startsAtToFilter === null || startsAtValue <= startsAtToFilter) ? allConditionRespected : false;
      }
      if (allConditionRespected && (filters.class2.date.hasOwnProperty('endAtFrom') || filters.class2.date.hasOwnProperty('endAtTo'))) {
        const endAtFromFilter: number = filters.class2.date.endAtFrom;
        const endAtToFilter: number = filters.class2.date.endAtTo;
        const endsAtValue = this.parse(alerte.endsAt);
        allConditionRespected = (endAtFromFilter === undefined || endAtFromFilter === null || endsAtValue >= endAtFromFilter) &&
        (endAtToFilter === undefined || endAtToFilter === null || endsAtValue <= endAtToFilter) ? allConditionRespected : false;
      }
      return allConditionRespected;
    });
  }

  parse(value: string): number {
    return moment(value, 'YYYY-MM-DDThh:mm:ss.SSSZ').toDate().getTime();
  }
}
