import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.css']
})
export class DashboardCardComponent implements OnInit {

  @Input()
  title;

  @Input()
  showInfo;

  @Input()
  iconCLass = 'fa fa-info-circle';

  @Input()
  footerText;

  constructor() { }

  @Output() infoClick: EventEmitter<any> = new EventEmitter<any>();
  infoWasClicked(clickedInfo: Element): void {
    this.infoClick.emit(null);
  }
  ngOnInit() {
  }

}
