import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-refresh-page',
  templateUrl: './refresh-page.component.html',
  styleUrls: ['./refresh-page.component.css']
})
export class RefreshPageComponent implements OnInit {
  @Input() intervalVal: number;
  stopInterval = null;
  isrefresh = false;
  isStoped = true;
  loading: boolean;
  @Output() getRefresh: EventEmitter<any> = new EventEmitter<any>();

  constructor() {   this.intervalVal = null; }

  ngOnInit() {
  }


  refresh() {
    this.loading = true;
    this.getRefresh.emit(null);
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  autoRefresh() {

    this.isrefresh = true;
    this.isStoped = false;
    if ((this.intervalVal === null)) {
      this.intervalVal = 10;
    }
    this.stopInterval = setInterval(() => this.refresh(), (this.intervalVal * 1000));

  }

  stop() {
    this.isrefresh = false;
    this.isStoped = true;
    this.intervalVal = null;
    clearInterval(this.stopInterval);
  }
}
