import { Component, OnInit, Input } from '@angular/core';
import {
  Resource
} from '../../../modules/navigation/models/resource';


@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.css']
})
export class AlertCardComponent implements OnInit {

  @Input()
  resource: Resource;

  @Input()
  title;

  @Input()
  filters: any;

  constructor() { }


  ngOnInit() {
  }
}
