import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareCiDataService {

  public teamName: string;
  public teams: Array<string>;
  public teamsStorageKey = 'favoriteTeams';

  constructor() {
  }

  getTeam(): string {
    return this.teamName;
  }

  setTeam(teamName: string) {
    this.teamName = teamName;
  }

  private favoriteTeamsInLocalStorage() {
    return localStorage.getItem(this.teamsStorageKey) !== null;
  }

  getFavoriteTeamNames(): Array<string> {
    if (this.favoriteTeamsInLocalStorage()) {
      return JSON.parse(localStorage.getItem(this.teamsStorageKey));
    }
    return [];
  }

  setFavoriteTeamNames(listTeamsNames: Array<string>) {
    return this.teams = listTeamsNames;
  }

}
