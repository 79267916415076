<div class="card boxed h-100">

  <div class="card-body">
    <div class="card-title">
      {{title}}
    </div>

    <div class="card-text">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="card-footer pl-4" *ngIf="footerText">{{footerText}}</div>
</div>
