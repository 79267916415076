import { environment} from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable} from 'rxjs';
import { Pods} from 'src/app/modules/navigation/models/pods';
import { PodInfoForInventory } from 'src/app/modules/navigation/models/pod-info-for-inventory';
import { K8SService } from 'src/app/modules/navigation/models/K8SService';
import { K8SIngress } from 'src/app/modules/navigation/models/K8SIngress';

const urlContainer = environment.urlBackend + '/metrics/pods';
// const urlContainerMock = 'assets/listPodsError.json';

@Injectable({
  providedIn: 'root'
})
export class ContainersService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient) {
  }

  getPodsWithInfoByCluster(clusterList: Array<string>): Observable <Map<string, PodInfoForInventory>> {
    return this.http.get <Map<string, PodInfoForInventory>> (urlContainer + '/info?clusterList='+ encodeURI(JSON.stringify(clusterList)), this.httpOptions);
  }
  getAllPodsInError(clusterList: Array<string>): Observable<Array<Pods>> {
     return this.http.get<Array<Pods>> (urlContainer + '/error?clusterList=' + encodeURI(JSON.stringify(clusterList)), this.httpOptions);
  }

  getServicesByCluster(clusterList: Array<string>): Observable< Array<K8SService>>{
    return this.http.get<Array<K8SService>>(urlContainer + '/service?clusterList=' + encodeURI(JSON.stringify(clusterList)), this.httpOptions);
  }

  getIngressByCluster(clusterList: Array<string>): Observable<K8SIngress[]> {
    return this.http.get<K8SIngress[]>(urlContainer + '/ingress?clusterList=' + encodeURI(JSON.stringify(clusterList)), this.httpOptions);
  }
  getAllCluster(): Observable<string[]> {
    return this.http.get<string[]>(urlContainer + '/cluster');
  }
}
