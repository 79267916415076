import { Component } from '@angular/core';


@Component({
  selector: 'app-common-base',
  template: `<p>common-base template!</p>`
})
export class CommonBaseComponent {

  pageSizeOptions = [ 5, 10, 20 , 40];
  pageSize = 5;
  pageSizeTop = 10;
  favoritesClusters = [];
  clusterList: string[] = [];

  constructor() {
    this.favoritesClusters = [];
    this.clusterList = [];
  }

  atLeastOneFavoriteCluster(): boolean {
    let existe = false;
    this.favoritesClusters = [];
    this.clusterList.forEach((_cluster) => {
      if (localStorage.getItem('favorite_clusters') !== null && JSON.parse(localStorage.getItem('favorite_clusters')).length) {
        existe = true;
        this.favoritesClusters = JSON.parse(localStorage.getItem('favorite_clusters'));
      }
    });
    return existe;
  }

}
