import { Alert} from './alert';
import { Annotations } from './annotations';
import { Labels } from './labels';
import { Status } from './status';

export class ResourceAlert {
  type: string;
  name: string;
  annotations: Annotations;
  labels: Labels;
  endsAt: string ;
  startsAt: string ;
  status: Status;
  alertSize: number;
  hasCritical: boolean;
  clusterName: string;
  expended: boolean[];

  constructor(type, name, annotations,labels,endsAt,startsAt,status,alertSize,hasCritical,clusterName, expended) {
    this.type = type;
    this.name = name;
    this.annotations = annotations;
    this.labels = labels;
    this.endsAt = endsAt;
    this.startsAt = startsAt;
    this.status = status;
    this.alertSize = alertSize;
    this.hasCritical = hasCritical;
    this.clusterName = clusterName;
    this.expended = expended;
  }

  
  init_attrs(alerts?: ResourceAlert){
    //let usedAlerts = this.alerts;
    
    this.alertSize = 0
    
        this.clusterName = this.labels.cc_prom_source;
        if (!this.labels.cluster) {
          // alert.labels.cluster is not send often by the backend.
          // alert.labels.cc_prom_source is always there and is the cluster name.
          this.labels.cluster = this.labels.cc_prom_source;
        }
        if (this.labels.severity === 'critical') {
          this.hasCritical = true;
        }
    
  }
}
