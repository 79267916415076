<mat-form-field floatPlaceholder="never" appearance="standard" id="select-component-{{selectKey}}" class="m-0 p-0">
    <mat-label>{{labelOrPlaceholder}}</mat-label>
    <mat-select [formControl]="selectMultiCtrl" placeholder="{{placeholderOrLabel}}" [multiple]="true" #multiSelect (selectionChange)="change($event)">
      <mat-option>
        <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)" [toggleAllCheckboxChecked]="toggleAllCheckboxChecked()" placeholderLabel="{{searchString}}" noEntriesFoundLabel="{{notFoundString}}" [formControl]="selectMultiFilterCtrl">
          <mat-icon ngxMatSelectSearchClear>{{'SELECT.DELETE' | translate}}</mat-icon>
        </ngx-mat-select-search>
      </mat-option>
      <mat-icon *ngIf="allowClearFavorites && hasFavorites()" (click)="emptyFavorites()" class="far fa-star iconePosition">{{'SELECT.CLEAR-FAVORITE' | translate}} {{selectKey}}</mat-icon>
      <mat-option *ngFor="let name of filteredSelectMulti | async" [value]="name">
        {{getSelectValueStr(name)}}
        <i [hidden]="!useFavorites" *ngIf="!isFavorite(name)" (click)="toggleFavorite($event, name)" class="far fa-star iconePosition"></i>
        <i [hidden]="!useFavorites" *ngIf="isFavorite(name)" (click)="toggleFavorite($event, name)" class="fas fa-star iconePosition"></i>
      </mat-option>
    </mat-select>
  </mat-form-field>
