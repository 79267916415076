import { coerceBooleanProperty } from '@angular/cdk/coercion';

class DateInterval {
  from: number;
  to: number;

  constructor() {
    this.from = null;
    this.to = null;
  }

  toString() {
    if (!this.from && !this.to) {
      return '';
    }
    if (this.from && this.to) {
      return `{ from: ${this.from}, to: ${this.to} }`;
    }
    if (this.from) {
      return `{ from: ${this.from} }`;
    }
    if (this.to) {
      return `{ to: ${this.to} }`;
    }
  }
}

class JobDate {
  start: DateInterval;
  end: DateInterval;

  constructor() {
    this.start = new DateInterval();
    this.end = new DateInterval();
  }

  toString() {
    const start = this.start.toString();
    const end = this.end.toString();
    if (start === '' && end === '') {
      return '';
    }
    if (start !== '' && end !== '') {
      return `{ start: ${start}, end: ${end} }`;
    }
    if (start !== '' && end === '') {
      return `{ start: ${start} }`;
    }
    if (start === '' && end !== '') {
      return `{ end: ${end} }`;
    }
  }
}

class GroupParams {
  name: string;
  jobs: string[];

  toString() {
    const name = this.name;
    const jobs = this.jobs;
    const outStrs = [];
    if (name) {
      outStrs.push(`name: "${name}"`);
    }
    if (jobs) {
      outStrs.push(`jobs: ${JSON.stringify(jobs)}`);
    }
    if (outStrs.length === 0) {
      return '';
    }
    return `{ ${outStrs.join(', ')} }`;
  }
}

export class PipelineFilter {
  id: number;
  name: string;
  // @ts-ignore
  team_name: string;
  // @ts-ignore
  team_name_in: string[];
  groups: GroupParams[];
  // @ts-ignore
  name_reg: string;
  // @ts-ignore
  team_name_reg: string;
  // @ts-ignore
  show_unfinished_jobs: boolean;
  status: string;
  // @ts-ignore
  status_in: string[];
  // @ts-ignore
  job_date: JobDate;
  // @ts-ignore
  restrict_jobs_query: boolean;
  // @ts-ignore
  job_name_reg: string;
  // @ts-ignore
  job_status_in: string[];

  constructor() {
    this.id = null;
    this.name = null;
    this.team_name = null;
    this.team_name_in = null;
    this.groups = null;
    this.name_reg = null;
    this.team_name_reg = null;
    this.show_unfinished_jobs = true;
    this.status = null;
    this.status_in = null;
    this.job_date = new JobDate();
    this.restrict_jobs_query = true;
    this.job_name_reg = null;
    this.job_status_in = null;
  }

  toString() {
    const outStrs = [];
    const attrs = ['id', 'name', 'team_name', 'team_name_in', 'groups', 'name_reg', 'team_name_reg', 'show_unfinished_jobs', 'job_name_reg'];
    for (const attr of attrs) {
      if (this[attr] && this[attr] !== '') {
        outStrs.push(`${attr}: ${JSON.stringify(this[attr])}`);
      }
    }
    if (this.groups) {
      const groupsStrs = [];
      for (const group of this.groups) {
        if (group && group.toString() !== '') {
          groupsStrs.push(group.toString());
        }
      }
      if (groupsStrs.length > 0) {
        outStrs.push(`groups: [ ${groupsStrs.join(', ')} ]`);
      }
    }
    if (this.status && this.status !== '') {
      outStrs.push(`status: ${this.status}`);
    }
    if (this.status_in) {
      outStrs.push(`status_in: [ ${this.status_in.join(', ')} ]`);
    }
    if (this.job_status_in) {
      outStrs.push(`job_status_in: [ ${this.job_status_in.join(', ')} ]`);
    }
    if (this.job_date && this.job_date.toString() !== '') {
      outStrs.push(`job_date: ${this.job_date.toString()}`);
    }
    if (this.restrict_jobs_query) {
      outStrs.push(`restrict_jobs_query: ${JSON.stringify(this.restrict_jobs_query)}`);
    }
    if (outStrs.length === 0) {
      return '';
    }
    return outStrs.join(', ');
  }
}


interface PipelineFilterFieldsCheckBox {
  jobSucceed: boolean;
}

interface PipelineFilterFieldsDate {
  startsAtFrom: any;
  startsAtTo: any;
  endAtFrom: any;
  endAtTo: any;
}

export class PipelineFormFilterFields {
  jobName: string;
  checkbox: PipelineFilterFieldsCheckBox;
  date: PipelineFilterFieldsDate;

  constructor() {
    this.init();
  }

  init(): void {
    this.jobName = '';
    this.checkbox = {
      jobSucceed: false
    };
    this.date = {
      startsAtFrom: null,
      startsAtTo: null,
      endAtFrom: null,
      endAtTo: null
    };
  }

  getFormFilters() {
    const filters = Object.assign({}, this);
    if (filters.jobName === '') {
      delete filters.jobName;
    }
    Object.keys(filters.checkbox).forEach(key => {
      if (!filters.checkbox[key]) {
        delete filters.checkbox[key];
      }
    });
    Object.keys(filters.date).forEach(key => {
      if (filters.date[key] === null) {
        delete filters.date[key];
      }
    });
    return filters;
  }
}
