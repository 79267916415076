import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Alert} from '../../modules/navigation/models/alert';
import { environment } from '../../../environments/environment';
import { Params } from 'src/app/modules/navigation/models/alertApi/params';
import { AlertSilenceParam } from 'src/app/modules/navigation/models/alertSilenceParam';

// const urlAlertWorningMock = 'assets/alertWarningMock.json';
// const urlAlertcriticalMock = 'assets/alertCritical.json';
// const urlAlertsMock = 'assets/alerts.json';
// const urlAlertWorningCountMock = 'assets/alertWorningCountMock.json';
// const urlAlertcriticalCountMock = 'assets/alertCriticalCountMock.json';

export const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private urlAlertApi: string;
  constructor(private http: HttpClient) {
    this.urlAlertApi = environment.urlBackend + '/alerts';
  }


  getAllAlert(active: boolean, clusters: Array<string>): Observable<Map<string, Alert[]>> {
    const params = new Params(active, clusters);
    return this.http.get<Map<string, Alert[]>>(this.urlAlertApi + '/alerts?params=' + encodeURI(JSON.stringify(params)));
    // return this.http.get<Map<string, Alert[]>>(urlAlertsMock);

  }
  getAllAlertCloudApp(active: boolean, clusters: Array<string>): Observable<Map<string, Alert[]>> {
    const params = new Params(active, clusters);
    return this.http.get<Map<string, Alert[]>>(this.urlAlertApi + '/appAlerts?params=' + encodeURI(JSON.stringify(params)));
    // return this.http.get<Map<string, Alert[]>>(urlAlertsMock);

  }

  sendSilenceRequest(alertSilenceParam: AlertSilenceParam): Observable<any> {
    const headers = {'content-type' : 'application/json'};
    const body = JSON.stringify(alertSilenceParam);
    console.log(body);
    return this.http.post(this.urlAlertApi + '/silences', body, {headers});
  }

  deleteSilence(id): Observable<any> {
    return this.http.delete(this.urlAlertApi + '/silences' + `/${id}`, HTTP_OPTIONS);
  }


  // getAllAlert(active: boolean, cluster: string): Observable<Map<string, Alert[]>> {
  //   return this.http.get<Map<string, Alert[]>>(this.urlAlertApi + '/alerts?active=' + active + '&cluster=' + cluster);
  // }

  getCountWarning(clusters: Array<string>): Observable<number> {
    return this.http.get<number>(this.urlAlertApi + '/warning/count?clusterList=' + encodeURI(JSON.stringify(clusters)));
  }
  getCountCritical(clusters: Array<string>): Observable<number> {
    return this.http.get<number>(this.urlAlertApi + '/critical/count?clusterList=' + encodeURI(JSON.stringify(clusters)));
  }
}
