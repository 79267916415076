import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { ShareDataService } from 'src/app/core/services/share-data.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { MultipleSelectionSelectComponent } from '../multiple-selection-select/multiple-selection-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multiple-selection-select-all-cluster',
  templateUrl: '../multiple-selection-select/multiple-selection-select.component.html',
  styleUrls: ['../multiple-selection-select/multiple-selection-select.component.css']
})
export class MultipleSelectionSelectAllClusterComponent extends MultipleSelectionSelectComponent implements OnDestroy, AfterViewInit {

  /** list of clusters */
  @Input()
  get clusters() {
    return this.selectValues;
  }

  set clusters(values) {
    this.selectValues = values;
  }

  @Input()
  get selectedClusters() {
    return this.selectedList;
  }

  set selectedClusters(values) {
    this.selectedList = values;
  }

  @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private sharedData: ShareDataService, utilsService: UtilsService, private translate: TranslateService) {
    super(utilsService);
    this.selectKey = 'clusters';
    this.placeholder = 'Clusters';
    this.useFavorites = true;
    this.allowClearFavorites = false;
    this.changeOnInit = false;
  }

  ngOnInit(): void {
    this.label = this.translate.instant('SELECT.CLUSTER');
    this.searchString = this.translate.instant('SELECT.FIND_CLUSTER');
    this.notFoundString = this.translate.instant('SELECT.NO_CLUSTERS');
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.setInitialValueToMatOption();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValueToMatOption() {
    this.filteredSelectMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: string, b: string) => a && b && a === b;
      });
  }

  change(_event?) {
    this.sharedData.setClusters(this.selectMultiCtrl.value);
    super.change(_event);
  }
}
