import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Pipeline } from 'src/app/modules/navigation/models/pipeline';
import { Apollo, gql, QueryRef, WatchQueryOptions } from 'apollo-angular';
import { PipelineFilter } from 'src/app/modules/navigation/models/pipeline-filter';
import { Observable, of } from 'rxjs';
import { HttpLink } from 'apollo-angular/http';
import { getMainDefinition, StoreObject } from '@apollo/client/utilities';
import { switchMap, take } from 'rxjs/operators';
import { ApolloQueryResult, DocumentNode, FetchResult, InMemoryCache, split, SubscriptionOptions } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { KeycloakSecurityService } from './KeycloakService';
import { JobsPipeline } from 'src/app/modules/navigation/models/jobsPipeline';
import { NgbdModalContentComponent } from 'src/app/shared/components/ngbd-modal-content/ngbd-modal-content.component';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { doOnSubscribe } from 'src/app/util/rxjsUtil';
import * as _ from 'lodash';
import { BuildLog } from 'src/app/modules/navigation/models/build';
import { BuildAttrs, DisplayedBuildAttrs } from 'src/app/shared/enums/pipeline-status-field.enum';
import { default as AnsiUp } from 'ansi_up';


const urlBackend = environment.urlBackend + '/cicd';
const wsBackend = environment.wsBackend;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

function getAllKeyFields(object: Readonly<StoreObject>) {
  return Object.keys(object);
}

// TODO: make the class declarations below DRY by using Mixins if someones understands the utterly crap documentation without losing their mind.
// Since multiple inheritance this way is not human friendly and is unreadable : https://www.stevefenton.co.uk/2014/02/typescript-mixins-part-one/
// We do it the dirty way :-(  :
class CiObservableApolloQueryResult extends Observable<ApolloQueryResult<any>> {
  ciGraphqlQuery?;
  ciGraphqlOptions?: WatchQueryOptions<any> | SubscriptionOptions<any>;
  ciSubscription?;
}

class CiObservableFetchResult extends Observable<FetchResult<any>> {
  ciGraphqlQuery?;
  ciGraphqlOptions?: WatchQueryOptions<any> | SubscriptionOptions<any>;
  ciSubscription?;
}

class CiQueryRef extends QueryRef<any> {
  ciGraphqlQuery?;
  ciGraphqlOptions?: WatchQueryOptions<any> | SubscriptionOptions<any>;
  ciSubscription?;
}

function isClosed(queryRef: CiQueryRef | CiObservableFetchResult | CiObservableApolloQueryResult) {
  if (queryRef.ciSubscription) {
    return queryRef.ciSubscription.closed;
  }
  return undefined;
}

function unsubscribe(queryRef: CiQueryRef | CiObservableFetchResult | CiObservableApolloQueryResult) {
  if (queryRef && queryRef.ciSubscription) {
    console.log('Unsubscribing to query: ' + queryRef.ciGraphqlQuery.loc);
    // console.log('Unsubscribing to query: ' + queryRef.ciGraphqlQuery.loc.source.body);
    queryRef.ciSubscription.unsubscribe();
    while (!queryRef.ciSubscription.closed) {
      // wait
    }
  }
}

function isQueryEqual(
  queryOne: CiQueryRef | CiObservableFetchResult | CiObservableApolloQueryResult,
  queryTwo: CiQueryRef | CiObservableFetchResult | CiObservableApolloQueryResult,
  bodyOnly?: boolean
) {
  if (!queryOne || !queryTwo) {
    return queryOne === queryTwo;
  }
  const queries: Array<any> = [queryOne, queryTwo];
  const bodies: Array<any> = [undefined, undefined];
  const closed: Array<any> = [undefined, undefined];

  for (const index in queries) {
    if (queries[index].ciGraphqlQuery) {
      bodies[index] = queries[index].ciGraphqlQuery.loc.source.body;
    }
    if (queries[index].ciSubscription) {
      closed[index] = queries[index].ciGraphqlQuery.closed;
    }
  }
  const bodiesEqual = bodies[0] === bodies[1];
  if (bodyOnly) {
    return bodiesEqual;
  } else {
    const bothClosedOrOpened = closed[0] === closed[1];
    return bodiesEqual && bothClosedOrOpened;
  }
}

interface BuildQueryRef {
  [buildId: number]: CiQueryRef | CiObservableFetchResult | CiObservableApolloQueryResult;
}

interface JobQueryRef {
  [jobId: number]: BuildQueryRef;
}

interface LogsQueryRefs {
  [PipelineId: number]: JobQueryRef;
}

@Injectable({
  providedIn: 'root'
})
export class CiService {

  // data;
  // error;
  ainsiUp = new AnsiUp();
  private _expandedPipeline: Pipeline | null;
  logsQueryRefs: LogsQueryRefs = {};

  get expandedPipeline() {
    return this._expandedPipeline;
  }

  set expandedPipeline(pipeline) {
    if (pipeline) {
      this.unsubscribeOtherPipelineBuildSubscriptions(pipeline);
    } else {
      this.unsubscribeAllBuildLogsSubscriptions();
    }
    this._expandedPipeline = pipeline;
  }

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private kcService: KeycloakSecurityService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.unsubscribeAllBuildLogsSubscriptions();

    const httpUrl = httpLink.create(
      {uri: urlBackend + '/graphql'}
    );
    const wsUrl = new WebSocketLink(
      {
        uri: wsBackend + '/graphql',
        options: {
          reconnect: true,
          connectionParams: () => {
            return {Authorization: 'Bearer ' + this.kcService.checkToken()};
          },
        }
      }
    );
    const link = split(
      // split based on operation type
      ({query}) => {
        return this.checkIsSubscription(query);
      },
      wsUrl,
      httpUrl,
    );

    apollo.createDefault(
      {
        link,
        cache: new InMemoryCache({
          typePolicies: {
            Pipeline: {
              // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-by-type
              // By default apollo stores pipelines in cache identified by their `id` property.
              // Pipelines get their jobs modified (after a call to cloneDeep) when we add the text formatted logs in jobs.
              // Since after cloneDeep the clone object has the exact same `id` field, it is automatically replaced in the cache.
              // So we need to change the identifier of the cache to always return the unmodified original instead of the clone.
              // Since the 'jobs' key gets modified when adding logs, we could add it as an identifier key to be sure to
              // retrieve the unmodified original object from the cache as so:
              // keyFields: ['id', 'name', 'jobs'],
              // However we do not always request for the jobs property in a graphql query, and if a key is not retrieved and requested
              // we get an exception.
              // So instead we make a dynamic list of all the available fields on the Pipeline object, and use them all as keyFields.
              keyFields: getAllKeyFields
            },
          }
        }),
        // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.watchQuery:
        /*defaultOptions: {
          watchQuery: {
            fetchPolicy: 'network-only',
          },
        }*/
      });
  }

  checkIsSubscription(query) {
    const mainDefinition = getMainDefinition(query);
    return (
      mainDefinition.kind === 'OperationDefinition' && mainDefinition.operation === 'subscription'
    );
  }

  clearCache() {
    this.apollo.client.resetStore();
  }

  ciGraphql(query: any, variables?: any): CiObservableApolloQueryResult {
    const options: WatchQueryOptions<any> = {query};
    if (variables) {
      options.variables = variables;
    }
    const observable = this.apollo.watchQuery<any>(options).valueChanges as CiObservableApolloQueryResult;
    observable.ciGraphqlQuery = query;
    observable.ciGraphqlOptions = options;
    if (this.checkIsSubscription(query)) {
      return observable;
    } else {
      return observable.pipe(take(1));
    }
  }

  ciGraphqlQueryRef(query: any, variables?: any): CiQueryRef {
    const options: SubscriptionOptions<any> = {query};
    if (variables) {
      options.variables = variables;
    }
    const observable = this.apollo.watchQuery<any>(options) as CiQueryRef;
    observable.ciGraphqlQuery = query;
    observable.ciGraphqlOptions = options;
    return observable;
  }

  ciGraphqlSubscription(query: any, variables?: any): CiObservableFetchResult {
    const options: SubscriptionOptions<any> = {query};
    if (variables) {
      options.variables = variables;
    }
    const observable = this.apollo.subscribe<any>(options) as CiObservableFetchResult;
    observable.ciGraphqlQuery = query;
    observable.ciGraphqlOptions = options;
    return observable;
  }

  countPipelines(queryParams?: string | PipelineFilter, asSubscription?: boolean): Observable<number> {
    let query: DocumentNode;
    let queryString: string;
    if (queryParams) {
      queryString = typeof queryParams === 'string' ? queryParams : queryParams.toString();
      queryString = queryString === '' ? '' : `(${queryString})`;
    } else {
      queryString = '';
    }
    if (asSubscription) {
      query = gql(`subscription {
        pipelines${queryString} {
          pipelines {
            id
          }
        }
      }`);
    } else {
      query = gql(`query {
        pipelines${queryString} {
          id
        }
      }`);
    }
    if (asSubscription) {
      return this.ciGraphqlSubscription(query).pipe(
        switchMap(response => {
          return of(response.data.pipelines.pipelines.length);
        })
      );
    } else {
      return this.ciGraphqlQueryRef(query).valueChanges.pipe(
        switchMap(response => {
          return of(response.data.pipelines.length);
        })
      );
    }
  }

  getPipelines(team: string): Observable<Pipeline[]> {
    return this.http.get<Pipeline[]>(urlBackend + '/teams/' + team + '/pipelines/', httpOptions);
  }

  openPipelineModal(
    modalService: NgbModal,
    options: NgbModalOptions,
    locale: string,
    pipeline: Pipeline,
    job: JobsPipeline
  ) {
    const modalRef = modalService.open(NgbdModalContentComponent, options);
    let buildAttr;
    for (buildAttr of DisplayedBuildAttrs) {
      if (this.hasBuild(pipeline, job, buildAttr, BuildAttrs.next_build, true)) {
        break;
      } else {
        buildAttr = BuildAttrs.finished_build;
      }
    }
    modalRef.componentInstance.header = job[buildAttr].header;
    modalRef.componentInstance.job = job;
    modalRef.componentInstance.buildAttr = buildAttr;
    modalRef.componentInstance.concourseUrl = job[buildAttr].concourseUrl;
    modalRef.componentInstance.ciService = this;
  }

  formatJobBuildLogs(job: JobsPipeline, buildAttr: BuildAttrs = BuildAttrs.finished_build) {
    if (job[buildAttr] != null) {
      job[buildAttr].logs_text = '';
      if (job[buildAttr].logs) {
        // we reverse instead of sorting on timestamp because timestamps are by second and some logs have the same timestamp.
        // if we `.sort((log1, log2) => log2[0] - log1[0])` we do not reverse properly because logs which have milliseconds of
        // difference have the same timestamp.
        // This means we have to rely on the backend always sending in order.
        for (const log of job[buildAttr].logs.slice().reverse()) {
          if (log.timestamp !== undefined && log.message !== undefined) {
            const date = new Date(log.timestamp * 1000);
            const html = this.ainsiUp.ansi_to_html(log.message);
            job[buildAttr].logs_text += '<strong>' + date.toLocaleString() + ' :</strong> <code>' + html + '</code><br />';
          }
        }
      }
    }
  }

  setJobExtraAttributes(job: JobsPipeline, pipeline: Pipeline, buildAttr: BuildAttrs = BuildAttrs.finished_build) {
    if (job[buildAttr]) {
      if (buildAttr === BuildAttrs.finished_build) {
        job[buildAttr].header = 'job: ' + job.name + ', date : ' + new DatePipe(this.locale).transform(job[buildAttr].end_time, 'dd/MM/yyyy HH:mm:ss');
      } else {
        job[buildAttr].header = 'job: ' + job.name + ', date : ' + new DatePipe(this.locale).transform(job[buildAttr].start_time, 'dd/MM/yyyy HH:mm:ss');
      }
      job[buildAttr].concourseUrl = job[buildAttr].url;
    }
  }

  getLogsSubscriptionQuery(buildId: number) {
    const logsQuery = `subscription {
      logs(build_id: ${buildId}) {
        logs {
          timestamp
          message
        }
      }
    }`;
    return gql(logsQuery);
  }

  getBuildLogs(pipeline, job, buildAttr) {
    if (
      // next_build logs are always live logs. When they close they are no longer a "next_build":
      (buildAttr === BuildAttrs.next_build && !this.hasAliveBuildSubscription(pipeline.id, job.id, job[buildAttr].id))
      ||
      // For other build types:
      (buildAttr !== BuildAttrs.next_build && !this.getBuildSubscription(pipeline.id, job.id, job[buildAttr].id))
    ) {
      const logsQueryRef = this.ciGraphqlSubscription(this.getLogsSubscriptionQuery(job[buildAttr].id));
      const ciSubscription = logsQueryRef.pipe(
        doOnSubscribe(() => {
          console.log('Unsubscribing to eventual previous subscription for Pipeline: ' + pipeline.id + ', Job: ' + job.id + ', Build: ' + job[buildAttr].id);
          unsubscribe(this.getBuildSubscription(pipeline.id, job.id, job[buildAttr].id));
          this.unsubscribeOtherPipelineBuildSubscriptions(pipeline.id);
          this.sanitiseLogsQueryRefs();
          this.setLogsQueryRef(pipeline.id, job.id, job[buildAttr].id, logsQueryRef);
        })
      ).subscribe(({data}) => {
          this.setLogsQueryRef(pipeline.id, job.id, job[buildAttr].id, logsQueryRef);
          job[buildAttr].logs = _.cloneDeep(data.logs.logs) as BuildLog[];
          this.formatJobBuildLogs(job, buildAttr);
          this.setJobExtraAttributes(job, pipeline, buildAttr);
          job.displayedBuild = job[buildAttr];
        },
        err => {
          this.errorCiGraphql(err);
          console.log('Unsubscribing to subscription for Pipeline: ' + pipeline.id + ', Job: ' + job.id + ', Build: ' + job[buildAttr].id);
          unsubscribe(logsQueryRef);
          this.sanitiseLogsQueryRefs();
          job.displayedBuild = job.finished_build;
        },
        () => {
          console.log('Unsubscribing to subscription for Pipeline: ' + pipeline.id + ', Job: ' + job.id + ', Build: ' + job[buildAttr].id);
          unsubscribe(logsQueryRef);
          this.sanitiseLogsQueryRefs();
          job.displayedBuild = job.finished_build;
        }
      );
      logsQueryRef.ciSubscription = ciSubscription;
    }
  }

  hasBuild(pipeline: Pipeline, job: JobsPipeline, buildAttr: BuildAttrs, priorityAttr?: BuildAttrs, getBuildLogs?: boolean) {
    // If priorityAttr exists and for example has the value 'next_build' and if job['next_build'] ALSO exists,
    // this method will reply false to the existence of job['finished_build'] or any other if they also exist.
    // But if with the example above we have priorityAttr = 'next_build' and job['next_build'] doesn't exist,
    // we will still return true if job[buildAttr] exists even if buildAttr != priorityAttr.
    //
    // priorityAttr means that if job[priorityAttr] exists and only if it exists,
    // we will not return true to the existence of other jobs if they also exist.
    if (![null, undefined].includes(priorityAttr)) {
      const priorityExists = ![null, undefined].includes(job[priorityAttr]);
      if (buildAttr !== priorityAttr && priorityExists) {
        return false;
      }
    }
    const buildExists = ![null, undefined].includes(job[buildAttr]);
    if (getBuildLogs) {
      if (buildAttr === BuildAttrs.next_build && buildExists) {
        this.getBuildLogs(pipeline, job, buildAttr);
      } else {
        job.displayedBuild = job.finished_build;
      }
    }
    return buildExists;
  }

  unsubscribeAllBuildLogsSubscriptions() {
    for (const pipelineIdStr of Object.keys(this.logsQueryRefs)) {
      const pipelineId = parseInt(pipelineIdStr, 10);
      for (const jobIdStr of Object.keys(this.logsQueryRefs[pipelineId])) {
        const jobId = parseInt(jobIdStr, 10);
        for (const buildIdStr of Object.keys(this.logsQueryRefs[pipelineId][jobId])) {
          const buildId = parseInt(buildIdStr, 10);
          const queryRef = this.logsQueryRefs[pipelineId][jobId][buildId];
          console.log('Unsubscribing to query for Pipeline: ' + pipelineId + ', Job: ' + jobId + ', Build: ' + buildId);
          unsubscribe(queryRef);
          delete this.logsQueryRefs[pipelineId][jobId][buildId];
        }
        delete this.logsQueryRefs[pipelineId][jobId];
      }
      delete this.logsQueryRefs[pipelineId];
    }
  }

  getBuildSubscription(pipelineId, jobId, buildId) {
    if (pipelineId in this.logsQueryRefs) {
      if (jobId in this.logsQueryRefs[pipelineId]) {
        if (buildId in this.logsQueryRefs[pipelineId][jobId]) {
          return this.logsQueryRefs[pipelineId][jobId][buildId];
        }
      }
    }
    return undefined;
  }

  hasAliveBuildSubscription(pipelineId, jobId, buildId) {
    const queryRef = this.getBuildSubscription(pipelineId, jobId, buildId);
    if (queryRef && queryRef.ciSubscription) {
      return !queryRef.ciSubscription.closed;
    }
    return false;
  }

  unsubscribePipelineJobBuildSubscription(pipelineId, jobId, buildId) {
    const queryRef = this.getBuildSubscription(pipelineId, jobId, buildId);
    if (queryRef) {
      console.log('Unsubscribing to query for Pipeline: ' + pipelineId + ', Job: ' + jobId + ', Build: ' + buildId);
      unsubscribe(queryRef);
      delete this.logsQueryRefs[pipelineId][jobId][buildId];
      if (Object.keys(this.logsQueryRefs[pipelineId][jobId]).length === 0) {
        delete this.logsQueryRefs[pipelineId][jobId];
      }
      if (Object.keys(this.logsQueryRefs[pipelineId]).length === 0) {
        delete this.logsQueryRefs[pipelineId];
      }
    }
  }

  unsubscribePipelineJobBuildSubscriptions(pipelineId, jobId) {
    if (pipelineId in this.logsQueryRefs) {
      if (jobId in this.logsQueryRefs[pipelineId]) {
        for (const buildIdStr of Object.keys(this.logsQueryRefs[pipelineId][jobId])) {
          const buildId = parseInt(buildIdStr, 10);
          this.unsubscribePipelineJobBuildSubscription(pipelineId, jobId, buildId);
        }
      }
    }
  }

  unsubscribePipelineBuildSubscriptions(pipelineId) {
    if (pipelineId in this.logsQueryRefs) {
      for (const jobIdStr of Object.keys(this.logsQueryRefs[pipelineId])) {
        const jobId = parseInt(jobIdStr, 10);
        this.unsubscribePipelineJobBuildSubscriptions(pipelineId, jobId);
      }
    }
  }

  unsubscribeOtherPipelineBuildSubscriptions(pipelineId) {
    for (const pipelineIdStr of Object.keys(this.logsQueryRefs)) {
      const otherPipelineId = parseInt(pipelineIdStr, 10);
      if (otherPipelineId !== pipelineId) {
        this.unsubscribePipelineBuildSubscriptions(otherPipelineId);
      }
    }
  }

  unsubscribeJObBuildSubscriptions(jobId) {
    for (const pipelineIdStr of Object.keys(this.logsQueryRefs)) {
      const pipelineId = parseInt(pipelineIdStr, 10);
      if (jobId in this.logsQueryRefs[pipelineId]) {
        this.unsubscribePipelineJobBuildSubscriptions(pipelineId, jobId);
      }
    }
  }

  unsubscribeBuildSubscription(buildId) {
    for (const pipelineIdStr of Object.keys(this.logsQueryRefs)) {
      const pipelineId = parseInt(pipelineIdStr, 10);
      for (const jobIdStr of Object.keys(this.logsQueryRefs[pipelineId])) {
        const jobId = parseInt(jobIdStr, 10);
        if (buildId in this.logsQueryRefs[pipelineId][jobId]) {
          this.unsubscribePipelineJobBuildSubscription(pipelineId, jobId, buildId);
        }
      }
    }
  }

  sanitiseLogsQueryRefs() {
    for (const pipelineIdStr of Object.keys(this.logsQueryRefs)) {
      const pipelineId = parseInt(pipelineIdStr, 10);
      for (const jobIdStr of Object.keys(this.logsQueryRefs[pipelineId])) {
        const jobId = parseInt(jobIdStr, 10);
        for (const buildIdStr of Object.keys(this.logsQueryRefs[pipelineId][jobId])) {
          const buildId = parseInt(buildIdStr, 10);
          const queryRef = this.logsQueryRefs[pipelineId][jobId][buildId];
          if (isClosed(queryRef)) {
            delete this.logsQueryRefs[pipelineId][jobId][buildId];
          }
        }
        if (Object.keys(this.logsQueryRefs[pipelineId][jobId]).length === 0) {
          delete this.logsQueryRefs[pipelineId][jobId];
        }
      }
      if (Object.keys(this.logsQueryRefs[pipelineId]).length === 0) {
        delete this.logsQueryRefs[pipelineId];
      }
    }
  }

  setLogsQueryRef(pipelineId, jobId, buildId, queryRef: CiQueryRef | CiObservableFetchResult | CiObservableApolloQueryResult) {
    if (queryRef) {
      const oldQueryRef = this.getBuildSubscription(pipelineId, jobId, buildId);
      if (!isQueryEqual(oldQueryRef, queryRef, true)) {
        if (oldQueryRef) {
          console.log('Unsubscribing to older query for Pipeline: ' + pipelineId + ', Job: ' + jobId + ', Build: ' + buildId);
          unsubscribe(oldQueryRef);
        }
        if (!(pipelineId in this.logsQueryRefs)) {
          this.logsQueryRefs[pipelineId] = {};
        }
        if (!(jobId in this.logsQueryRefs[pipelineId])) {
          this.logsQueryRefs[pipelineId][jobId] = {};
        }
        console.log('Storing new query for Pipeline: ' + pipelineId + ', Job: ' + jobId + ', Build: ' + buildId);
        this.logsQueryRefs[pipelineId][jobId][buildId] = queryRef;
      }
    }
  }

  errorCiGraphql(error) {
    console.log(error);
  }

}
