    <mat-card class="node-card">
        <mat-card-header class="d-flex justify-content-between align-items-center text-primary">
          <mat-card-title>
            {{ title }}
          </mat-card-title>
        </mat-card-header>               
          
        <mat-card-content class="overview-card-content">
          <ul class="list-group">
            <div *ngFor="let node of nodes">
              <app-node-item [node]="node" [type]="type"></app-node-item>
            </div>
          </ul>
        </mat-card-content>
      </mat-card>
  