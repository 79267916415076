<div class="row col-12">
  <div class="col-3"></div>
  <div class="card-deck-wrapper col-6">
    <div class="card-deck">
      <div class="card bg-light">
        <div class="card-block pl-2 pt-2 text-center">
          <i class="fas fa-exclamation-triangle fa-5x"></i>
          <h1 class="card-title mb-2">{{'ERROR.OUPS' | translate}}</h1>
          <p class="card-text mb-2">
          <p>{{'ERROR.UNABLE-OPEN-PAGE' | translate}}</p>
          <p>{{'ERROR.CONTACT-SUPPORT' | translate}}</p>
          <a href="#">{{'ERROR.GOTO-HOME' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3"></div>
</div>
