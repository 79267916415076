import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbDate, NgbTimeStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgModel, FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {

  regexDateTime = "(0[1-9]|[1-2][0-9]|3[0-1])[-](0[1-9]|1[0-2])[-][0-9]{4} (00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])$";
  startsAtFromCtrldp: FormControl;
  startsAtToCtrldp: FormControl;
  startsAtFromCtrltp: FormControl;
  startsAtToCtrltp: FormControl;
  startsAtFromCtrl: FormControl;
  startsAtToCtrl: FormControl;
  startsAtFromGrp: FormGroup;
  startsAtToGrp: FormGroup;
  startsAtFromPickerGrp: FormGroup;
  startsAtToPickerGrp: FormGroup;

  endAtFromCtrldp: FormControl;
  endAtToCtrldp: FormControl;
  endAtFromCtrltp: FormControl;
  endAtToCtrltp: FormControl;
  endAtFromCtrl: FormControl;
  endAtToCtrl: FormControl;
  endAtFromGrp: FormGroup;
  endAtToGrp: FormGroup;
  endAtFromPickerGrp: FormGroup;
  endAtToPickerGrp: FormGroup;

  startsAtFromPopoverNavigation = false;
  startsAtToPopoverNavigation = false;
  endAtFromPopoverNavigation = false;
  endAtToPopoverNavigation = false;

  favoriteFilterSaved = false;
  filterModified = false;
  filterBlank;

  @Output()
  groupFilters: EventEmitter<any> = new EventEmitter<any>();
  filters: any;
  inputDatetimeFormat = 'dd-MM-yyyy HH:mm:ss';
  date: any;

  blankFilter : any = {
    class1: {
      text: {
        alertOrigin: ''
      }
    },
    class2: {
      text: {
        alertName: '', alertMessage: ''
      },
      date: {
        startsAtFrom: null,
        startsAtTo: null,
        endAtFrom: null,
        endAtTo: null
      },
      checkbox: {
        critical: true,
        warning: true
      },
      typeAlert:{
        silentAlert: true,
        nonSilentAlert: true
      }
    }
  }
  fields: any = {
    class1: {
      text: {
        alertOrigin: ''
      }
    },
    class2: {
      text: {
        alertName: '', alertMessage: ''
      },
      date: {
        startsAtFrom: null,
        startsAtTo: null,
        endAtFrom: null,
        endAtTo: null
      },
      checkbox: {
        critical: true,
        warning: true
      },
      typeAlert:{
        silentAlert: true,
        nonSilentAlert: true
      }
    }
  }
  oldFields: any =  {
    class1: {
      text: {
        alertOrigin: ''
      }
    },
    class2: {
      text: {
        alertName: '', alertMessage: ''
      },
      date: {
        startsAtFrom: null,
        startsAtTo: null,
        endAtFrom: null,
        endAtTo: null
      },
      checkbox: {
        critical: true,
        warning: true
      },
      typeAlert:{
        silentAlert: true,
        nonSilentAlert: true
      }
    }
  };


  constructor(private fb: FormBuilder, @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit() {
    let favoriteAlertFilter : string = localStorage.getItem('favoriteAlertFilter');
    if(favoriteAlertFilter){
      this.favoriteFilterSaved = true;
      this.fields = JSON.parse(favoriteAlertFilter);
    }
    this.filterBlank = this.compareFilters(this.fields,this.getBlankFilter());
    this.startsAtFromCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.startsAtFromCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.startsAtFromCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.startsAtFromPickerGrp = this.fb.group({
      startsAtFromCtrldp: this.startsAtFromCtrldp,
      startsAtFromCtrltp: this.startsAtFromCtrltp,
    });
    this.startsAtFromGrp = this.fb.group({
      startsAtFromCtrl: this.startsAtFromCtrl,
      startsAtFromPicker: this.startsAtFromPickerGrp
    });

    this.startsAtToCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.startsAtToCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.startsAtToCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.startsAtToPickerGrp = this.fb.group({
      startsAtToCtrldp: this.startsAtToCtrldp,
      startsAtToCtrltp: this.startsAtToCtrltp,
    });
    this.startsAtToGrp = this.fb.group({
      startsAtToCtrl: this.startsAtToCtrl,
      startsAtToPicker: this.startsAtToPickerGrp
    });

    this.endAtFromCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.endAtFromCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.endAtFromCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.endAtFromPickerGrp = this.fb.group({
      endAtFromCtrldp: this.endAtFromCtrldp,
      endAtFromCtrltp: this.endAtFromCtrltp,
    });
    this.endAtFromGrp = this.fb.group({
      endAtFromCtrl: this.endAtFromCtrl,
      endAtFromPicker: this.endAtFromPickerGrp
    });

    this.endAtToCtrldp = this.fb.control(this.initDatePicker(), [Validators.required]);
    this.endAtToCtrltp = this.fb.control(this.initTimePicker(), [Validators.required]);
    this.endAtToCtrl = this.fb.control('', [Validators.required, Validators.pattern(this.regexDateTime)]);
    this.endAtToPickerGrp = this.fb.group({
      endAtToCtrldp: this.endAtToCtrldp,
      endAtToCtrltp: this.endAtToCtrltp,
    });
    this.endAtToGrp = this.fb.group({
      endAtToCtrl: this.endAtToCtrl,
      endAtToPicker: this.endAtToPickerGrp
    });

    this.updateFilters();
  }

  createNgbDateStruct(startsAtFromValueMoment: moment.Moment): NgbDateStruct {
    return {
      year: startsAtFromValueMoment.year(), month: startsAtFromValueMoment.month() + 1, day: startsAtFromValueMoment.date()
    }
  }

  createNgbTimeStruct(startsAtFromValueMoment: moment.Moment): NgbTimeStruct {
    return {
      hour: startsAtFromValueMoment.hour(), minute: startsAtFromValueMoment.minute(), second: startsAtFromValueMoment.second()
    }
  }

  initTimePicker(): NgbTimeStruct {
    const dateA = new Date();
    return { hour: dateA.getHours(), minute: dateA.getMinutes(), second: dateA.getSeconds() };
  }

  initDatePicker(): NgbDateStruct {
    const dateA = new Date();
    return { year: dateA.getFullYear(), month: dateA.getMonth() + 1, day: dateA.getDate() };
  }


  compareDate(startsAtFromValueMoment: moment.Moment, filterName: string): boolean {
    return (this[filterName+'Ctrldp'].value.year === startsAtFromValueMoment.year() &&
    this[filterName+'Ctrldp'].value.month === (startsAtFromValueMoment.month() + 1) &&
    this[filterName+'Ctrldp'].value.day === startsAtFromValueMoment.date() &&
    this[filterName+'Ctrltp'].value.hour === startsAtFromValueMoment.hour() &&
    this[filterName+'Ctrltp'].value.minute === startsAtFromValueMoment.minute() &&
    this[filterName+'Ctrltp'].value.second === startsAtFromValueMoment.second());
  }

  toggle(popover, filterName :string) {
    this[filterName+'PopoverNavigation'] = popover.isOpen() ? false : true;
  }

  navigation(datePickerTemplate: NgbDatepicker, filterName :string) {
    if (this[filterName+'PopoverNavigation']) {
      datePickerTemplate.navigateTo(this[filterName+'Ctrldp'].value);
    }
    this[filterName+'PopoverNavigation'] = false;
  }


  updateDateFilter(inputValue: string, filterName: string) {
    if (this[filterName+'Ctrl'].status === "VALID" && this[filterName+'Ctrl'].value != null
        && this[filterName+'Ctrl'].value != undefined) {

      let momentValue = moment(inputValue, "DD-MM-YYYY hh:mm:ss");
      this.fields.class2.date[filterName] = momentValue.toDate().getTime();
      if (!this.compareDate(momentValue, filterName)) {
        this[filterName+'Ctrldp'].setValue(this.createNgbDateStruct(momentValue));
        this[filterName+'Ctrltp'].setValue(this.createNgbTimeStruct(momentValue));
      }
    }else{
      this.fields.class2.date[filterName] = null;
    }
    this.updateFilters();
  }

  updatePicker(filterName: string): void {
    if (this[filterName+'Ctrltp'].status !== "VALID" || this[filterName+'Ctrltp'].value === null || this[filterName+'Ctrltp'].value === undefined) {
      this[filterName+'Ctrltp'].setValue(this.initTimePicker());
    }
    let date = new Date(
      this[filterName+'Ctrldp'].value.year, this[filterName+'Ctrldp'].value.month - 1,
      this[filterName+'Ctrldp'].value.day, this[filterName+'Ctrltp'].value.hour,
      this[filterName+'Ctrltp'].value.minute, this[filterName+'Ctrltp'].value.second
    );
    this[filterName+'Ctrl'].setErrors(null);
    this[filterName+'Ctrl'].setValue(new DatePipe(this.locale).transform(date, this.inputDatetimeFormat));
  }

  nonSilentAlert(): void{
    this.fields.class2.typeAlert.nonSilentAlert = !this.fields.class2.typeAlert.nonSilentAlert;
    this.updateFilters();
  }

  silentAlert(): void{
    this.fields.class2.typeAlert.silentAlert = !this.fields.class2.typeAlert.silentAlert;
    this.updateFilters();
  }

  warningAlert(): void{
    this.fields.class2.checkbox.warning = !this.fields.class2.checkbox.warning;
    this.updateFilters();
  }
  updateFilters(): void {
    this.filterBlank = this.compareFilters(this.fields, this.getBlankFilter());
    this.filterModified = !this.compareFilters(this.oldFields,this.fields);
    this.filters = Object.assign({}, this.fields);
    Object.keys(this.filters['class1']['text']).forEach(key => {
      this.filters['class1']['text'][key] === '' ? delete this.filters['class1']['text'][key] : key;
    });
    Object.keys(this.filters['class2']['text']).forEach(key => {
      this.filters['class2']['text'][key] === '' ? delete this.filters['class2']['text'][key] : key;
    });
    Object.keys(this.filters['class2']['date']).forEach(key => {
      this.filters['class2']['date'][key] === null ? delete this.filters['class2']['date'][key] : key;
    });
    this.groupFilters.emit(this.filters);
    this.oldFields = this.cloneFilter(this.fields);
  }



  saveFavoriteSearch(){
    localStorage.removeItem("favoriteAlertFilter");
    localStorage.setItem("favoriteAlertFilter",JSON.stringify(this.filters));
    this.favoriteFilterSaved = true;
    this.filterModified = false;
  }

  clearFavoriteSearch(){
    this.clearFilter();
    this.favoriteFilterSaved = false;
    localStorage.removeItem("favoriteAlertFilter");
  }

  clearFilter(){
    this.fields = this.getBlankFilter();
    this.updateFilters();
  }
  getBlankFilter() : any{
    return this.cloneFilter(this.blankFilter);
  }

  cloneFilter (filter : any) : any{
    var stringFilter = JSON.stringify(filter);

    return JSON.parse(stringFilter);
  }

  compareFilters(oldFilter : any, newFilter : any) : boolean{
    const res : boolean = (oldFilter === null && newFilter === null ) ||
      (this.emptyStringIfEmpty(oldFilter.class1.text.alertOrigin) === this.emptyStringIfEmpty(newFilter.class1.text.alertOrigin) &&
        this.emptyStringIfEmpty(oldFilter.class2.text.alertName) === this.emptyStringIfEmpty(newFilter.class2.text.alertName) &&
          this.emptyStringIfEmpty(oldFilter.class2.text.alertMessage) === this.emptyStringIfEmpty(newFilter.class2.text.alertMessage) &&
          oldFilter.class2.date.startsAtFrom == newFilter.class2.date.startsAtFrom &&
          oldFilter.class2.date.startsAtTo == newFilter.class2.date.startsAtTo &&
          oldFilter.class2.date.endAtFrom == newFilter.class2.date.endAtFrom &&
          oldFilter.class2.date.endAtTo == newFilter.class2.date.endAtTo &&
          oldFilter.class2.checkbox.critical == newFilter.class2.checkbox.critical &&
          oldFilter.class2.checkbox.warning == newFilter.class2.checkbox.warning &&
          oldFilter.class2.typeAlert.silentAlert == newFilter.class2.typeAlert.silentAlert &&
          oldFilter.class2.typeAlert.nonSilentAlert == newFilter.class2.typeAlert.nonSilentAlert);
      return res;
  }

  emptyStringIfEmpty(str : string): string {
    let res : string ="";
    if(str !== null && str !== undefined){
      res = str;
    }
    return res;
  }
}

