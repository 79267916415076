import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-ngbd-modal-content',
  templateUrl: './ngbd-modal-content.component.html',
  styleUrls: ['./ngbd-modal-content.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NgbdModalContentComponent implements OnInit, OnDestroy {

  @Input() header;
  @Input() job;
  @Input() buildAttr;
  @Input() footer;
  @Input() concourseUrl;
  @Input() ciService;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ciService.unsubscribeBuildSubscription(this.job[this.buildAttr].id);
  }

}
