import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class KeycloakSecurityService {

  constructor(protected router: Router,
              protected keycloakAngular: KeycloakService) {
  }

  logout() {
    this.keycloakAngular.logout();
  }

  getToken(): string {
    return this.keycloakAngular.getKeycloakInstance().token;
  }

  getTokenParsed() {
    return this.keycloakAngular.getKeycloakInstance().tokenParsed;
  }

  isLogged(): boolean {
    return this.keycloakAngular.getKeycloakInstance()?.authenticated;
  }

  login() {
    return this.keycloakAngular.login();
  }

  checkToken(): string {
    if (this.keycloakAngular.isTokenExpired()) {
      this.logout();
    }
    return this.getToken();
  }

}
