import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    let browserLang: string;
    if (localStorage.getItem('locale')) {
      browserLang = localStorage.getItem('locale');
      translate.use(this.isValidLang(browserLang) ? browserLang : translate.defaultLang);
    } else {
      browserLang = this.isValidLang(translate.getBrowserLang()) ? translate.getBrowserLang() : translate.defaultLang;
      this.changeLang(browserLang);
    }
  }

  isValidLang(language: string) {
    return this.translate.langs.includes(language);
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  getLangName(language: string) {
    return this.translate.instant(`HOME.LANGUAGES.${language.toUpperCase()}`);
  }

  get currentLang() {
    let browserLang = localStorage.getItem('locale');
    if (!this.isValidLang(browserLang)) {
      browserLang = this.translate.defaultLang;
    }
    return browserLang? browserLang : this.translate.currentLang;
  }
}
