<div class="modal-header">
    <h4 class="modal-title">{{header}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [innerHtml]="job[buildAttr].logs_text | safeHtml"></div>
</div>
<div class="modal-footer">
    <ng-template #tipContent>{{'ERROR.GOTO-JOB-DETAIL-ERROR' | translate}}</ng-template>
    <div style="width:300px;position: absolute;left: 0;">
        <a type="button" [href]="concourseUrl" placement="right" [ngbTooltip]="tipContent"  target="_blank" class="btn btn-primary btn-block" style="font-weight:bold;white-space:normal; background-color: #062166;border-color: #062166;  font-weight: bold;">
            <i class="iconify" data-icon="mdi-concourse-ci" data-inline="false"
            aria-hidden="true"></i>
            <span>{{'CI.CONCOURSE-LOGS' | translate}}</span>
    </a>
    </div>
    <div type="button" class="btn-sm my-0 btnClose textMargin" (click)="activeModal.close('Close click')">
        <span class="iconify" data-icon="ion:close" data-inline="false" data-width="30" data-height="27"></span>
      {{'MODAL.CLOSE' | translate}}</div>

</div>
