import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../environments/environment';

const { keycloakConfig } = environment;


export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return () =>  initKeycloak(keycloak) ;
  }

async function initKeycloak(keycloak: KeycloakService) {
  await keycloak.init({
    config: keycloakConfig,
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false
    },
    enableBearerInterceptor: true,
    bearerExcludedUrls: []
  });
}

