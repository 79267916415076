import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {ServerErrorComponent} from './server-error/server-error.component';
import {PageUnavailableComponent} from './page-unavailable/page-unavailable.component';

const routes: Routes = [
    {
        path : '',
       // canActivate: [AuthGuard],
        loadChildren: () => import('src/app/modules/navigation/navigation.module').then(m => m.NavigationModule)
    },
    {
      path: 'serverError',
      component: ServerErrorComponent
    },
    {
      path: 'unavailable',
      component: PageUnavailableComponent
    },
    {
        path : '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
