import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notif-container',
  templateUrl: './notif-container.component.html',
  styleUrls: ['./notif-container.component.css']
})
export class NotifContainerComponent implements OnInit {

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  constructor(
    private toastrService: ToastrService
    ) { }

  ngOnInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;
  }

}
