import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../core/services/language.service';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslatePageComponent {
  constructor(public translate: TranslateService, private language: LanguageService) { }

  isValidLang(language: string) {
    return this.language.isValidLang(language);
  }

  changeLang(language?: string) {
    this.language.changeLang(language);
  }

  getLangName(language: string) {
    return this.language.getLangName(language);
  }

  get currentLang() {
    return this.language.currentLang;
  }

  set currentLang(value) {
    this.changeLang(value);
  }
}
