// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: window['env']['KeycloakUrl'] || '',
  realm: window['env']['realm'] || '',
  clientId: window['env']['clientId'] || '',
};

export const environment = {
  production: false,
  urlBackend: window['env']['urlBackend'] || '',
  wsBackend: window['env']['wsBackend'] || '',
  grafanaUrl: window['env']['grafanaUrl'] || '',
  bandeauInformation: window['env']['bandeauInformation'] || '',
  karmaUrl:  window['env']['karmaUrl'] || '',
  rancherUrl: window['env']['rancherUrl'] || '',
  quayUrl: window['env']['quayUrl'] || '',
  vaultUrl: window['env']['vaultUrl'] || '',
  keycloakUrl: window['env']['keycloakBaseUrl'] || '',
  giteaUrl: window['env']['giteaUrl'] || '',
  docUrl: window['env']['docUrl'] || '',
  concourseUrl: window['env']['concourseUrl'] || '',
  helpdeskUrl: window['env']['helpdeskUrl'] || '',
  mySupport: window['env']['mySupport'] || '',
  zoneName: window['env']['zoneName'] || '',
  keycloakConfig,
  nodesRessourcesUrl: window["env"]["nodesRessourcesUrl"] || '',
  nodesCpuUrl: window["env"]["nodesCpuUrl"] || '',
  nodesMemoryUrl: window["env"]["nodesMemoryUrl"] || '',
  nodesVolumesUrl: window["env"]["nodesVolumesUrl"] || '',
  nodesTotalPodsUrl: window["env"]["nodesTotalPodsUrl"] || '',
  podsRessourcesUrl: window["env"]["podsRessourcesUrl"] || '',
  podsCpuUrl: window["env"]["podsCpuUrl"] || '',
  podsMemoryUrl: window["env"]["podsMemoryUrl"] || '',
  podsVolumesUrl:window["env"]["podsVolumesUrl"] || '',
}; 